import React from "react";
import back2 from "/webapps/lms_react/src/assets/Mask Group 356.png";
import mob2 from "/webapps/lms_react/src/assets/mob2.png";
import productimg from "/webapps/lms_react/src/assets/Group 1395.png";
import emptycart from "/webapps/lms_react/src/assets/Group 162311.png"
import "./style/Shoppingcart.css";
import Navbar from "./Navbar";
import Footer from "./footer";
import { Link } from "react-router-dom";
import wing from "/webapps/lms_react/src/assets/search-interface-symbol.png";
import newslettericon from "/webapps/lms_react/src/assets/Newsletter.png";

export default function ShoppingCart() {
  return (
    <div>
      <Navbar />
      <div className="sofware-background">
        <picture>
      <source className="img" media="(max-width:767px)" srcset={mob2} />
      <source className="img" srcset={back2} media="(min-width: 768px) and (max-width: 1023px)"  />
      <source media="(min-width: 1024px)" srcset={back2} />
      <img className="img" src={back2} />
      </picture>
        <div className=" container overlay-text-bg">
          <h2>Software Engineer</h2>
          <p>
            Home / Courses
          </p>
          <span> / Shopping Cart</span>
        </div>
      </div>

      <div className="table-container container pt-5 ">
        <div class="table-responsive">
        <table class="table table-bordered  ">
          <thead>
            <tr>
              <th className="removetablecol" scope="col"></th>
              <th style={{ textAlign: "start" }} scope="col">
                Product
              </th>
              <th scope="col">Price</th>
              <th scope="col">Quantity</th>
              <th scope="col">Subtotal</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">
                <span class="close"></span>
              </th>
              <td>
                <div class="card-table mb-3">
                  <div class="row g-0">
                    <div class="col-md-2 col-2">
                      <img
                        src={productimg}
                        class="img-fluid rounded-start"
                        alt="..."
                      />
                    </div>
                    <div class="col-md-10 col-10">
                      <div class="card-body">
                        <p class="card-text">
                          How to learn JavaScript Lorem Ipsum is simply <br />{" "}
                          dummy text of the printing and typesetting industry.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td>$60.00</td>
              <td>
                <button class="quantity-btn">1</button>
              </td>
              <td>$60.00</td>
            </tr>
            <tr>
              <th scope="row">
                <span class="close"></span>
              </th>
              <td>
                <div class="card-table mb-3">
                  <div class="row g-0">
                    <div class="col-md-2 col-2">
                      <img
                        src={productimg}
                        class="img-fluid rounded-start"
                        alt="..."
                      />
                    </div>
                    <div class="col-md-10 col-10">
                      <div class="card-body">
                        <p class="card-text">
                          How to learn JavaScript Lorem Ipsum is simply <br />{" "}
                          dummy text of the printing and typesetting industry.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td>$60.00</td>
              <td>
                <button class="quantity-btn">1</button>
              </td>
              <td>$60.00</td>
            </tr>
          </tbody>
        </table>
</div>
        {/* <div class="sec-table pb-3">
          <table class="second-data-table" >
            <tr>
              <th class="table-title">Cart Total </th>
              <td></td>
            </tr>
            <tr>
              <th className="table-subtotal-title">Sub Total </th>
              <td>$600</td>
            </tr>
            <tr className="mid-sec-titles" >
              <th style={{display:"flex",flexDirection:"column"}}>Shipping
                <span>Flat Rate</span>
                <span>Shipping to MA.</span>

              </th>
              <td>$50.00</td>

              
            </tr>

          
            <tr>
              <th style={{fontSize:"16px",fontWeight:"400"}}>Change Shipping Address</th>
              <td></td>
            </tr>

            <tr class="total55">
              <th>Total</th>
              <td >$1710.00</td>
            </tr>
          </table>

        
        </div> */}

         <div class="thirdsec pb-3">
              <p>Total</p>
              <span>$1710.00</span>        
        </div> 
        <div class="center-btn-proceed">
        <Link to='/checkout'>
        <button class="proceed-btn">Proceed to checkout</button>
        </Link>
        </div>
      </div>
      
      <div class=" blue-banner2">

          <div class="container grid-layouts">

          <div class="cardnewsletter " >
  <div class="row no-gutters">
    <div class="col-md-3">
      <img src={newslettericon} class="newsletter-img"/>
    </div>
    <div class="col-md-9">
      <div class="card-body-newsletter">
        <h5 class="card-title-newsletter">Join our newsletter now!</h5>
        <p class="card-text-newsletter">Register now and get our latest updates and promos </p>
      </div>
    </div>
  </div>
</div>
          
          <div class="input-btn-sec">
              <input type="text" value="" placeholder="Enter your email" />
            <button class="join-btn" >
            Join
            </button>
          </div>
          </div>

        </div>

      {/* <div class="empty-cart">

      <img src={emptycart}/>
      <h6>Your cart is empty. Keep shopping to find a course!</h6>
      <button class="keep-shopping-btn">Keep shopping</button>

      </div> */}

      <Footer/>
    </div>
  );
}
