import React, { useState } from "react";
import Navbar from "./Navbar";
import checkoutimg from "/webapps/lms_react/src/assets/checkout.png";
import Footer from "./footer";
import "./style/Checkout.css";
import newslettericon from "/webapps/lms_react/src/assets/Newsletter.png";
import mob5 from "/webapps/lms_react/src/assets/mob5.png";

export default function Checkout() {
   
    const [selectedOption, setSelectedOption] = useState(null);

    const handleOptionChange = (e) => {
        const value = e.target.value;
        setSelectedOption(value === selectedOption ? null : value);
    };

  return (
    <div>
      <Navbar />
      <div className="sofware-background">
        <picture>
      <source className="img" media="(max-width:767px)" srcset={mob5} />
      <source className="img" srcset={checkoutimg} media="(min-width: 768px) and (max-width: 1023px)"  />
      <source media="(min-width: 1024px)" srcset={checkoutimg} />
      <img className="img" src={checkoutimg} />
      </picture>
        <div className=" container overlay-text-bg">
          <h2>Shopping Cart</h2>
          <p>
              <span style={{fontWeight:"500"}}> / Checkout </span>
          </p>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="container checkout-form-title col-md-8">
            <h6>Personal Info</h6>
            <form>
              <div className="row">
                <div class="mb-3 col-md-6 col-12">
                  <label for="exampleInputfirstname" class="form-label">
                    First Name
                  </label>
                  <input
                    type="firstname"
                    class="form-control"
                    id="exampleInputfirstname"
                  />
                </div>
                <div class="mb-3 col-md-6 col-12">
                  <label for="exampleInputlastname" class="form-label">
                    Last Name
                  </label>
                  <input
                    type="lastname"
                    class="form-control"
                    id="exampleInputlastname"
                  />
                </div>
              </div>
              <div className="row">
                <div class="mb-3 col-md-6 col-12">
                  <label for="exampleInputaddress" class="form-label">
                    Address
                  </label>
                  <input
                    type="address"
                    class="form-control"
                    id="exampleInputaddress"
                  />
                </div>
                <div class="mb-3 col-md-6 col-12">
                  <label for="exampleInputcontact" class="form-label">
                    Contact
                  </label>
                  <input
                    type="contact"
                    class="form-control"
                    id="exampleInputcontact"
                  />
                </div>
              </div>
              <div className="row">
                <div class="mb-3 col-md-6 col-12">
                  <label for="exampleInputcountry" class="form-label">
                    Country
                  </label>
                  <input
                    type="country"
                    class="form-control"
                    id="exampleInputcountry"
                  />
                </div>
                <div class="mb-3 col-md-6 col-12">
                  <label for="exampleInputcity" class="form-label">
                    City
                  </label>
                  <input
                    type="city"
                    class="form-control"
                    id="exampleInputcity"
                  />
                </div>
              </div>
              <div className="row">
                <div class="mb-3 col-md-6 col-12">
                  <label for="exampleInputzip" class="form-label">
                    State
                  </label>
                  <select
                    class="form-select form-select-lg mb-3"
                    aria-label=".form-select-lg example"
                  >
                    <option selected>Select State</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
                <div class="mb-3 col-md-6 col-12">
                  <label for="exampleInputzip" class="form-label">
                    Zip Code
                  </label>
                  <input
                    type="zipcode"
                    class="form-control"
                    id="exampleInputzip"
                  />
                </div>
              </div>
              <div className="row">
              <div class="mb-3  col-md-12">
                <label for="exampleInputnote" class="form-label">
                  Note
                </label>
                <input  type="note" class="form-control" id="exampleInputnote" />
              </div>
              </div>
            </form>
          </div>
          <div className="col-md-4">
            <div className="order-summary">
              <h5>Your Order Summary</h5>
              <div className="orderrow row">
                <div className="col-2">
                    <p>1x</p>
                </div>
                <div className="col-7">
                    <p>How to learn <br/> java script Lorem </p>
                </div>
                <div className="col-2">
                    <p>100.00$</p>
                </div>
              </div>
            <div className="subtotal-sec">
                <span>Subtotal</span>
                <p>$60.00</p>


            </div>
            <div className="subtotal-sec">
                <span>Shipping</span>
                <p>$15.00</p>


            </div>
            <div className="tax-sec">
                <span>Tax</span>
                <p>$10.00</p>


            </div>
            <div className="total-sec">
                <span>Total</span>
                <p>$625</p>
            </div>
            <div className="payment-sec">
                <span>Payment</span>
                <div class="check-payment-method row">
                    <div className="credit-label col-6">
                    <label >
                <input
                    type="radio"
                    value="credit"
                    checked={selectedOption === 'credit'}
                    onChange={handleOptionChange}
                />
                                <span className="checkmark"></span>

                Credit Card
            </label>
            
            </div>
            <div class="cash-label col-6">
            <label>
                <input
                    type="radio"
                    value="cash"
                    checked={selectedOption === 'cash'}
                    onChange={handleOptionChange}
                />
                Cash
            </label>
            </div>
        </div>


        <button class="Checkout-btn">Checkout</button>
            </div>

            </div>
          </div>
        </div>
      </div>
      <div class=" blue-banner2">

<div class="container grid-layouts">

<div class="cardnewsletter " >
<div class="row no-gutters">
<div class="col-md-3 col-12">
<img src={newslettericon} class="newsletter-img"/>
</div>
<div class="col-md-9 col-12">
<div class="card-body-newsletter">
<h5 class="card-title-newsletter">Join our newsletter now!</h5>
<p class="card-text-newsletter">Register now and get our latest updates and promos </p>
</div>
</div>
</div>
</div>

<div class="input-btn-sec">
    <input type="text" value="" placeholder="Enter your email" />
  <button class="join-btn" >
  Join
  </button>
</div>
</div>

</div>
      <Footer /> 
    </div>
  );
}

