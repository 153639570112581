import { combineReducers } from 'redux';
import filtersReducer from './filterReducer'; // Adjust the path accordingly
import searchReducer from './searchReducer';

const rootReducer = combineReducers({
  filters: filtersReducer,
  search: searchReducer
  // Add other reducers if needed
});

export default rootReducer