import React, { useState } from "react";
import "./style/softwarepage.css";
import back from "/webapps/lms_react/src/assets/Mask Group 35.png";
import singlecoursepic from "/webapps/lms_react/src/assets/pic2.png";
import { Link, NavLink } from "react-router-dom";
import Line from "/webapps/lms_react/src/assets/Line.svg";
import Navbar from "./Navbar";
import Footer from "./footer";
import singlecoursepic1 from "/webapps/lms_react/src/assets/Mask Group 37@2x.png";
import techerpic from "/webapps/lms_react/src/assets/pic3.png";
import lessonicon from "/webapps/lms_react/src/assets/Icon awesome-book-open.png";
import mob from "/webapps/lms_react/src/assets/mobviewforsoftware.png";

export default function Softwarepage() {
  const [content, setContent] = useState("Content 1");
  const [activeTag, setActiveTag] = useState("tag1");

  const handleClick = (e) => {
    const links = Array.from(document.querySelectorAll(".menu-link"));
    links.forEach((link) => {
      link.classList.remove("active");
    });
    e.target.classList.add("active");
    // Add your existing onClick action here
  };

  const changeContent = (id, tagname) => {
    switch (id) {
      case "most-btn":
        setContent("Content 1");
        break;
      case "new-btn":
        setContent("Content 2");
        break;
      case "bg-btn":
        setContent("Content 3");
        break;
      default:
        setContent("");
        break;
    }
    setActiveTag(tagname);
  };
  return (
    <div>
      <Navbar/>
      <div className="sofware-background">
      <picture>
      <source className="img" media="(max-width:767px)" srcset={mob} />
      <source className="img" srcset={back} media="(min-width: 768px) and (max-width: 1023px)"  />
      <source media="(min-width: 1024px)" srcset={back} />
      <img className="img" src={back} />
      </picture>     
         <div className=" container overlay-text-bg">
          <h2>Software Engineer</h2>
          <p><Link to="/" style={{textDecoration:"none",color:"#212529"}}>
            Home  / Courses </Link><span class="block-sec-pass"> / Software Engineer</span>
          </p>
        </div>
      </div>

      <div class="container">
        <div class="pre-text">
          <h6>
            <img src={Line} /> Courses to get your started
          </h6>
          <h3>Software Engineer</h3>
        </div>

        <div class="List">
          <ul>
            <li
              id="most-btn"
              onClick={() => changeContent("most-btn", "tag1")}
              className={`List-item2 ${activeTag === "tag1" ? "active" : ""}`}
              style={{ marginLeft: "0px" }}
            >
              Most popular
            </li>
            <li
              className={`List-item2 ${activeTag === "tag2" ? "active" : ""}`}
              id="new-btn"
              onClick={() => changeContent("new-btn", "tag2")}
            >
              New
            </li>
            <li
              className={`List-item2 ${activeTag === "tag3" ? "active" : ""}`}
              id="bg-btn"
              onClick={() => changeContent("bg-btn", "tag3")}
            >
              Beginner Favorites
            </li>
          </ul>
        </div>
        <div id="content1" style={{ display: content === "Content 1" ? "block" : "none" }}>
          <div class="list_of_card_software">
          <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>  <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>  <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>  <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>  <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>  <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>  <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>  <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div> 

          </div>
          <div class="loadmoresec">
            <button>Load More</button>
          </div>
        </div>
        <div id="content2" style={{ display: content === "Content 2" ? "block" : "none" }}>
        <div class="list_of_card_software">
          <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>  <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>  <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>  <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>  <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>  <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>  <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>  <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div> 

          </div>
          <div class="loadmoresec">
            <button>Load More</button>
          </div>
        </div>
        <div id="content3"style={{ display: content === "Content 3" ? "block" : "none" }}>
        <div class="list_of_card_software">
          <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>  <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>  <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>  <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>  <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>  <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>  <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>  <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div> 

          </div>
          <div class="loadmoresec">
            <button>Load More</button>
          </div>
        </div>
     

      </div>
      <Footer/>
    </div>
  );
}
