import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css/pagination";
import "./style/Reverseswiper.css";
import commenticon from "/webapps/lms_react/src/assets/comment-icon.png";
import oval from "/webapps/lms_react/src/assets/Oval.png";
export default function Reverseswiper() {
  return (
    <>
      <div class="swiper-reverse" dir="rtl">
        <div class="card10">
              <div class="card10-body">
                <h5 class="card-reverse-title-1">DUMMY TEXT</h5>
                <h6 class="card-reverse-title-2 pt-4">
                  What Students <br /> Say About Us
                </h6>
                <h5 class="card-reverse-title-3 pt-3">
                  Minuteness of the parts
                </h5>

                <p class="card-text pt-5">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry’s
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
              </div>
            </div>{" "}  
        <Swiper
          direction="horizontal"
          rtl={true}
          slidesPerView={1}
          spaceBetween={50}
          
          navigation={{
            nextEl: '.swiper-button-next1',
            prevEl: '.swiper-button-prev1',
          }}
          
          modules={[Navigation]}
          className="mySwiper"
          breakpoints={{
            // when window width is >= 576px (mobile)
            576: {
              slidesPerView:1, // Adjust the number of slides per view for mobile
              spaceBetween: 10 // Adjust the space between slides for mobile
            },
            // when window width is >= 768px (tablet)
            768: {
              slidesPerView: 1.4, // Adjust the number of slides per view for tablet
              spaceBetween: 50 // Adjust the space between slides for tablet
            },
            // when window width is >= 1024px (desktop)
            1024: {
              slidesPerView: 1.6, // Keep the original number of slides per view for desktop
              spaceBetween: 10 // Keep the original space between slides for desktop
            }
          }}
        >
          
          <SwiperSlide style={{paddingTop:"3rem",width:"auto"}}>
            <div class="card11">
              <div class="card11-body">
                <h5 class="card-reverse-title-1 pt-5">
                  <img class="comment-icon" src={commenticon} />
                </h5>
                <p class="card11-text pt-5">
                  “Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry’s
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book Lorem Ipsum is simply dummy text of the printing
                  and typesetting industry. Lorem Ipsum has been the industry’s
                  standard dummy.”
                </p>

                <div class="contact-info1">
                  <div class="contact-info-icon1">
                    <img src={oval} />
                  </div>

                  <div class="contact-info-content1">
                    <h4>Shady Abdullah</h4>
                    <p>Designer</p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide >
          <SwiperSlide style={{paddingTop:"3rem",width:"auto"}}>
            <div class="card11"style={{backgroundColor:"#50C4EE"}}>
              <div class="card11-body">
                <h5 class="card-reverse-title-1 pt-5">
                  <img class="comment-icon" src={commenticon} />
                </h5>
                <p class="card11-text pt-5">
                  “Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry’s
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book Lorem Ipsum is simply dummy text of the printing
                  and typesetting industry. Lorem Ipsum has been the industry’s
                  standard dummy.”
                </p>

                <div class="contact-info1">
                  <div class="contact-info-icon1">
                    <img src={oval} />
                  </div>

                  <div class="contact-info-content1">
                    <h4>Shady Abdullah</h4>
                    <p>Designer</p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide >          
          <SwiperSlide style={{paddingTop:"3rem"}}>
            <div class="card11">
              <div class="card11-body">
                <h5 class="card-reverse-title-1 pt-5">
                  <img class="comment-icon" src={commenticon} />
                </h5>
                <p class="card-text pt-5">
                  “Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry’s
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book Lorem Ipsum is simply dummy text of the printing
                  and typesetting industry. Lorem Ipsum has been the industry’s
                  standard dummy.”
                </p>

                <div class="contact-info1">
                  <div class="contact-info-icon1">
                    <img src={oval} />
                  </div>

                  <div class="contact-info-content1">
                    <h4>Shady Abdullah</h4>
                    <p>Designer</p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        
          <div class="navigation1">
          <div class="btn swiper-button-prev1"></div>
          <div class="btn swiper-button-next1"></div>
          </div>
          <div class="card12">
              <div class="card10-body">
                <h5 class="card-reverse-title-1">DUMMY TEXT</h5>
                <h6 class="card-reverse-title-2 pt-4">
                  What Students <br /> Say About Us
                </h6>
                <h5 class="card-reverse-title-3 pt-3">
                  Minuteness of the parts
                </h5>

                <p class="card-text pt-5">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry’s
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
              </div>
            </div>{" "} 
        </Swiper>
      </div>
    </>
  );
}
