import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import 'swiper/css/navigation';
import { Autoplay ,Navigation, Pagination } from "swiper/modules";
import "swiper/css/pagination";
import "./style/Firstslider.css"
import combinedshape from "/webapps/lms_react/src/assets/Combined Shape.png"
import { Link } from 'react-router-dom';
export default function Firstslider() {
  return (
    <div class="container-fluid first-section"style={{zIndex:"-1"}}  >
    <img className='combinedshape' src={combinedshape}style={{zIndex:"1"}}/>

    <div class="container">
    <Swiper  
  
      loop={true}
      autoplay={{
          delay: 2000,
          disableOnInteraction: false
      }}
      slidesPerView={1}
      spaceBetween={50}
      centeredSlides={true}
      pagination={{
        clickable: true,
      }}
      // navigation={{
      //   nextEl: '.swiper-button-next2',
      //   prevEl: '.swiper-button-prev2',
      // }}
        modules={[Autoplay, Pagination, Navigation]}
      className="mySwiper pb-5"
      breakpoints={{
        // when window width is >= 576px (mobile)
        576: {
          slidesPerView: 1, // Adjust the number of slides per view for mobile
          spaceBetween: 50 // Adjust the space between slides for mobile
        },
        // when window width is >= 768px (tablet)
        768: {
          slidesPerView: 1, // Adjust the number of slides per view for tablet
          spaceBetween: 50 // Adjust the space between slides for tablet
        },
        // when window width is >= 1024px (desktop)
        1024: {
          slidesPerView: 1.25, // Keep the original number of slides per view for desktop
          spaceBetween: 50 // Keep the original space between slides for desktop
        }
      }}
    >
      <SwiperSlide>
        <Link to="/coursedetails" style={{textDecorationLine:"none"}}>
        <div class="card15" >
        <div class="card-category5">
              {/* <p>SCIENCE</p> */}
            </div>
            <div class="card15-body">
              <h5 class="card15-title">Learn Object-Oriented <br/> Programming with PHP</h5>
              <p class="card15-text">Shady Amr</p>
                  <button class="price_btn2 btn btn-primary">$299</button>
            </div>
        </div> 
        </Link>  
        </SwiperSlide>
        <SwiperSlide>
        <Link to="/coursedetails" style={{textDecorationLine:"none"}}>
        <div class="card15" >
        <div class="card-category5">
              {/* <p>SCIENCE</p> */}
            </div>
            <div class="card15-body">
              <h5 class="card15-title">Learn Object-Oriented <br/> Programming with PHP</h5>
              <p class="card15-text">Shady Amr</p>
                  <button class="price_btn2 btn btn-primary">$299</button>
            </div>
        </div>   
        </Link>  
        </SwiperSlide>
        <SwiperSlide>
        <Link to="/coursedetails" style={{textDecorationLine:"none"}}>
        <div class="card15" >
        <div class="card-category5">
              {/* <p>SCIENCE</p> */}
            </div>
            <div class="card15-body">
              <h5 class="card15-title">Learn Object-Oriented <br/> Programming with PHP</h5>
              <p class="card15-text">Shady Amr</p>
                  <button class="price_btn2 btn btn-primary">$299</button>
            </div>
        </div>
        </Link>     
        </SwiperSlide>
        <SwiperSlide>
        <Link to="/coursedetails" style={{textDecorationLine:"none"}}>
        <div class="card15" >
        <div class="card-category5">
              {/* <p>SCIENCE</p> */}
            </div>
            <div class="card15-body">
              <h5 class="card15-title">Learn Object-Oriented <br/> Programming with PHP</h5>
              <p class="card15-text">Shady Amr</p>
                  <button class="price_btn2 btn btn-primary">$299</button>
            </div>
        </div>
        </Link>     
        </SwiperSlide>
        <SwiperSlide>
        <Link to="/coursedetails" style={{textDecorationLine:"none"}}>
        <div class="card15" >
        <div class="card-category5">
              {/* <p>SCIENCE</p> */}
            </div>
            <div class="card15-body">
              <h5 class="card15-title">Learn Object-Oriented <br/> Programming with PHP</h5>
              <p class="card15-text">Shady Amr</p>
                  <button class="price_btn2 btn btn-primary">$299</button>
            </div>
        </div>
        </Link>   
        </SwiperSlide>
      <SwiperSlide>
      <Link to="/coursedetails" style={{textDecorationLine:"none"}}>
        <div class="card15" >
        <div class="card-category5">
              {/* <p>SCIENCE</p> */}
            </div>
            <div class="card15-body">
              <h5 class="card15-title">Learn Object-Oriented <br/> Programming with PHP</h5>
              <p class="card15-text">Shady Amr</p>
                  <button class="price_btn2 btn btn-primary">$299</button>
            </div>
        </div>
        </Link>     
        </SwiperSlide>      <div class='navigation'>
        {/* <div class="swiper-button-prev2"></div>

      <div class="swiper-button-next2"></div> */}
      </div>
    </Swiper>
  </div> 
  </div> )
}
