import React, { useEffect, useState } from "react";
import "./style/Login.css";
import loginimg from "/webapps/lms_react/src/assets/Sign In Vec.svg";
import logo from "/webapps/lms_react/src/assets/Logo-02.png";
import { Link } from "react-router-dom";
import Footer from "./footer";
import axios from 'axios';

export default function Loginpage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [Data, setData] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('http://lmsapis.gosmart.ae/hello');
        // setData(response.data);
        console.log('Response is ',response.data)

      } catch (err) {

        console.log('error',err)
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures it runs only once





  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('http://localhost:4000/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
      const data = await response.json();

      if (response.ok) {
        // Handle successful login
        setMessage('Login successful');
      } else {
        // Handle login error
        setMessage(data.message || 'Login failed');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('An error occurred');
    }
  };
  return (
    <>
      <div class="container card-login ">
      <Link to="/"><img src={logo} class="rak-logo"/></Link>

        <div class="row ">
          <div class="col-md-6 rowforimg">
            <img src={loginimg} class="Login-card-img" alt="..." />
          </div>
          <div class="col-md-6">
            <div class="card-login-body">
              <div class="logo-img-container">
              <Link to="/"><img src={logo} class="logo33" /></Link>
            </div>
              <h5 class="card-title5">Login</h5>
              <h5 class="card-title6">Nice to see you again!</h5>
              <form  onSubmit={handleSubmit} >

                <div class="form-group100">
                  <label for="exampleInputEmail1">Username</label>
                  <input
                    type="email"
                    class="form-control"
                    value={email}
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter Username" 
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  
                </div>
                <div class="form-group100 pt-3">
                  <label for="exampleInputPassword1">Password</label>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    class="form-control"
                    id="exampleInputPassword1"
                    placeholder="Password"
                  />
                </div>

                 <div class="forgetpass-container ">
                  <Link to='/forgetpassword'>
                  <a href="">Forget password</a>
                  </Link>
                </div> 
                <div className=" login-btn-section">
                <button type="submit" class="Login-btn">
                  Login
                </button>

                </div>
                <div className="centerdont">

                <p>Don't have an account ? 
                  <Link to='/signuppage'>
                  <a href=""> Register here</a>
                  </Link>
                  </p>
                </div>
              </form>
              {message && <p>{message}</p>}

            </div>
          </div>
        </div>

      </div>
      <Footer/>

      </>
  );
}
