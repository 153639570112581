import React from "react";
import "./style/register.css";
import Timer from "./timer";

export default function Register() {
  return (
    <div className="wrap-reg">
    <div class=" container card3 mb-3 pt-5">
      <div class="flexing-register " style={{margin:"0px"}}>
          <div class="register-container">
            <h6 class="">CONTACT US</h6>

            <h5 class="pt-4">Register Now</h5>

            <h4 class="pt-3">Sky took on a wonderful</h4>

            <p className="pt-3">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry’s standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </p>

            <div>

              <Timer />
            </div>
          </div>
        <div class="register-section ">
            <form className="register-form">
              <div className="pb-3 ">
                <input type="text" id="fname" name="fname"  placeholder="Your Name"/>
              </div>
              <div className="pb-3">
                <input type="text" id="fname" name="fname"  placeholder="Your Email"/>
              </div>{" "}
              <div className="pb-3">
                <input type="text" id="fname" name="fname"  placeholder="Subject" />
              </div>
              <div className="pb-3">
                <textarea type="text" id="fname" name="fname"  placeholder="Message"></textarea>
              </div>
              <button class="btn btn-success float-end" style={{border:"0px"}}  >Submit</button>
            </form>
        </div>
      </div>
    </div>
    </div>
  );
}
