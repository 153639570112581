import * as React from "react";
import back from "/webapps/lms_react/src/assets/Mask Group 35.png";
import "./style/coursedetails.css";
import picrounded from "/webapps/lms_react/src/assets/picrounded.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import profilepic from "/webapps/lms_react/src/assets/Icon awesome-user-alt@2x.png";
import world from "/webapps/lms_react/src/assets/Icon material-language@2x.png";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import rightpic from "/webapps/lms_react/src/assets/rightpic.png";
import playbtn from "/webapps/lms_react/src/assets/play.png";
import { useState } from "react";
import grey from "/webapps/lms_react/src/assets/unnamed_8c511ccf-5b33-41c2-9ba6-78e4403db695_1200x1200.webp";
import { Link, NavLink } from "react-router-dom";
import Line from "/webapps/lms_react/src/assets/Line.svg";
import greenicon from "/webapps/lms_react/src/assets/Icon awesome-check-circle.svg";
import grey370icon from "/webapps/lms_react/src/assets/img@2x.png";
import verify from "/webapps/lms_react/src/assets/Path 2368.png";
import productimg from "/webapps/lms_react/src/assets/Group 1395.png";
import Footer from "./footer";
import Navbar from "./Navbar";
import xicon from "/webapps/lms_react/src/assets/Group 237.png";
import stars from "/webapps/lms_react/src/assets/Vector.svg";
import reviewpic from "/webapps/lms_react/src/assets/Rectangle 30187.png"
import enroll from "/webapps/lms_react/src/assets/Icon awesome-user-alt.svg"
import courselevel from "/webapps/lms_react/src/assets/Group 162324.svg"
import courseduration from "/webapps/lms_react/src/assets/Group 162325.svg"
import bluebook from "/webapps/lms_react/src/assets/Icon awesome-user-alt.svg"
import Coursesslider from "./coursesslider";
import blackbook from "/webapps/lms_react/src/assets/Icon awesome-book-open (1).svg"
import writereview from "/webapps/lms_react/src/assets/Group 162393@2x.png"
import mob from "/webapps/lms_react/src/assets/mobviewforsoftware.png";


export default function Coursedetails() {
  const [contentreview, setContentreview] = useState("AllReview");
  const [activeTagreview, setActiveTagreview] = useState("tag10");
  const [rating, setRating] = useState(0);

  const handleClick = (index) => {
    setRating(index + 1);
  };

  const changeContentreview = (id, tagname) => {
    switch (id) {
      case "All-btn-Review":
        setContentreview("AllReview");
        break;
      case "5-star-btn":
        setContentreview("5Content");
        break;
      case "4-star-btn":
        setContentreview("4Content");
        break;
      case "3-star-btn":
        setContentreview("3Content");
        break;
      case "2-star-btn":
        setContentreview("2Content");
        break;
      case "1-star-btn":
        setContentreview("1Content");
        break;


      default:
        setContentreview("");
        break;
    }
    setActiveTagreview(tagname);
  };
  const [content, setContent] = useState("Content 1");
  const [activeTag, setActiveTag] = useState("tag1");
  const [listactive, Setlistactive] = useState("tag1");
  const [Model, SetModal] = useState(false);
  const toggleModel = () => {
    SetModal(!Model);
  };

  const [Model2, SetModal2] = useState(false);

  const toggleModel2 = () => {
    SetModal2(!Model2);
  };
  const activelist = (tagname) => {
    Setlistactive(tagname);
  };

  const changeContent = (id, tagname) => {
    switch (id) {
      case "description-btn":
        setContent("Content 1");
        break;
      case "syllabus-btn":
        setContent("Content 2");
        break;
      case "reviews-btn":
        setContent("Content 3");
        break;

      default:
        setContent("");
        break;
    }

    setActiveTag(tagname);
  };

  return (
    <div>
      <Navbar />
      <div className="sofware-background">
        <picture>
          <source className="img" media="(max-width:767px)" srcset={mob} />
          <source className="img" srcset={back} media="(min-width: 768px) and (max-width: 1023px)" />
          <source media="(min-width: 1024px)" srcset={back} />
          <img className="img" src={back} />
        </picture>
        <div className=" container overlay-text-bg">
          <h2>Software Engineer</h2>
          <p>
            Home / Courses<span class="block-sec-pass"> / Software Engineer</span>
          </p>
          {/* <p> 
            Home / Courses / Software Engineer<span> / Course Details</span> 
          </p> */}
        </div>
      </div>

      <div class="container">
        <div class="pre-text">
          <h6>
            <img src={Line} style={{ marginRight: "10px" }} />
            Courses to get your started
          </h6>
          <h3>
            How to learn JavaScript Lorem Ipsum is simply dummy text of the
            printing and typesetting industry.
          </h3>
        </div>




        <div class="left-section  ">
          <div class="categorys ">
            <div className="category-selections">
              <button
                className={`category-item ${listactive === "tag1" ? "active" : ""
                  }`}
                onClick={() => activelist("tag1")}
              >
                Software Engineer{" "}
                <FontAwesomeIcon icon={faArrowRight} class="arrow2" />
              </button>
              <button
                className={`category-item ${listactive === "tag2" ? "active" : ""
                  }`}
                onClick={() => activelist("tag2")}
              >
                Computer Science{" "}
                <FontAwesomeIcon icon={faArrowRight} class="arrow2" />
              </button>
              <button
                className={`category-item ${listactive === "tag3" ? "active" : ""
                  }`}
                onClick={() => activelist("tag3")}
              >
                Health & Fitness{" "}
                <FontAwesomeIcon icon={faArrowRight} class="arrow2" />
              </button>
              <button
                className={`category-item ${listactive === "tag4" ? "active" : ""
                  }`}
                onClick={() => activelist("tag4")}
              >
                Software Engineer{" "}
                <FontAwesomeIcon icon={faArrowRight} class="arrow2" />
              </button>
              <button
                className={`category-item ${listactive === "tag5" ? "active" : ""
                  }`}
                onClick={() => activelist("tag5")}
              >
                Architecture{" "}
                <FontAwesomeIcon icon={faArrowRight} class="arrow2" />
              </button>
              <button
                className={`category-item ${listactive === "tag6" ? "active" : ""
                  }`}
                onClick={() => activelist("tag6")}
              >
                Art & Design{" "}
                <FontAwesomeIcon icon={faArrowRight} class="arrow2" />
              </button>
              <button
                className={`category-item ${listactive === "tag7" ? "active" : ""
                  }`}
                onClick={() => activelist("tag7")}
              >
                Adobe designing{" "}
                <FontAwesomeIcon icon={faArrowRight} class="arrow2" />
              </button>
              <button
                className={`category-item ${listactive === "tag8" ? "active" : ""
                  }`}
                onClick={() => activelist("tag8")}
              >
                Analysis of Algorithms{" "}
                <FontAwesomeIcon icon={faArrowRight} class="arrow2" />
              </button>
              <button
                className={`category-item ${listactive === "tag9" ? "active" : ""
                  }`}
                onClick={() => activelist("tag9")}
              >
                History Archaeologic{" "}
                <FontAwesomeIcon icon={faArrowRight} class="arrow2" />
              </button>
            </div>
            <div class="more-courses">
              <div class="more-courses-text">More course for you</div>
              <div class="more-courses-cards">
                <div class="card111 mb-3">
                  <div class="row no-gutters">
                    <div class="col-md-4 pt-4">
                      <img src={grey} class="card83-img" alt="..." />
                    </div>
                    <div class="col-md-8 firstcardcontent">
                      <div class="card111-body">
                        <h5 class="card111-title">
                          Complete education earning course 2020
                        </h5>
                        <p class="card111-text">$90.00</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card111 mb-3">
                  <div class="row no-gutters">
                    <div class="col-md-4">
                      <img src={grey} class="card83-img" alt="..." />
                    </div>
                    <div class="col-md-8">
                      <div class="card111-body">
                        <h5 class="card111-title">
                          Computer programming get certificate
                        </h5>
                        <p class="card111-text">Price: free</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card111 mb-3 pb-3">
                  <div class="row no-gutters">
                    <div class="col-md-4">
                      <img src={grey} class="card83-img" alt="..." />
                    </div>
                    <div class="col-md-8">
                      <div class="card111-body">
                        <h5 class="card111-title">
                          Computer programming get certificate
                        </h5>
                        <p class="card111-text">Price: free</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class=" firstrow-grid">
            <div class="row ">
              <div class="col-md-1 col-2">
                <img src={picrounded} />
              </div>
              <div class="col-md-3 col-4 author">
                <span>Author:</span>
                <p> Mennah Ahmed</p>
              </div>
              <div class="col-md-3 col-4" style={{ paddingRight: "0px" }}>
                <span>Reviews: </span>
                <p style={{ color: "gold" }}>
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                </p>
              </div>

              <div class="col-md-2 col-1" style={{ padding: "0px" }}>
                <span>Price: </span>
                <p>$299</p>
              </div>

              <div class="col-md-3 col-12 pt-2 click-me-div ">
                <button class=" clickme" onClick={toggleModel}>
                  {" "}
                  Add to Cart
                  <FontAwesomeIcon icon={faArrowRight} class="btnarrow" />
                </button>
              </div>
            </div>
            <div class="row pt-3 pb-3">
              <div class="col-md-4  col-7">
                <img src={enroll} class="profileicon" style={{ marginRight: "5px" }} />
                <span>Enrolled students 564</span>
              </div>
              <div class="col-md-3 col-5">
                <img src={courselevel} class="profileicon" style={{ marginRight: "3px" }} />
                <span>Intermediate</span>
              </div>
              <div class="col-md-3 col-7 paddingmob">
                <img src={courseduration} class="profileicon" style={{ marginRight: "5px" }} />
                <span>7 Days</span>
              </div>

              <div class="col-md-2 col-5  paddingmob2" >
                <img src={bluebook} class="worldicon" style={{ marginRight: "5px" }} />
                <span>20 Lessons</span>
              </div>

            </div>

            <div class="video-sec" >
              <img src={rightpic} className="rightpic" />
              <img src={playbtn} class="playbtn2" />
            </div>

            <div class="List-collapse">
              <ul>
                <li
                  id="details-btn"
                  className={`List-itemm ${activeTag === "tag1" ? "active" : ""
                    }`}
                  onClick={() => changeContent("description-btn", "tag1")}
                  style={{ marginLeft: "0px" }}
                >
                  Description
                </li>
                <li
                  className={`List-itemm ${activeTag === "tag2" ? "active" : ""
                    }`}
                  id="description-btn"
                  onClick={() => changeContent("syllabus-btn", "tag2")}
                >
                  Syllabus
                </li>
                <li
                  className={`List-itemm ${activeTag === "tag3" ? "active" : ""
                    }`}
                  id="info-btn"
                  onClick={() => changeContent("reviews-btn", "tag3")}
                >
                  Reviews
                </li>
              </ul>
            </div>
            <div
              id="content1"
              style={{ display: content === "Content 1" ? "block" : "none" }}
            >
              <div class="content1-title">What are features there include?</div>
              <div class="content1-text">
                Perferendis lacinia non, est distinctio ut eveniet, posuere mus
                nostrum eget itaque, irure illo leo. Est! Numquam <br /> autem
                ipsa! Dolores eiusmod, impedit bibendum porro! Error! Magna
                quia. Quia officia non? Lectus corporis <br /> laudantium cursus
                voluptas eveniet
              </div>
              <div class="content1-text2">
                Perferendis, voluptatum. Exercitation justo aliquip? Convallis
                ligula aptent aute ab? Sit necessitatibus error, quaerat <br />{" "}
                curae tristique tempore velit, nascetur ullam metus molestie,
                etiam sapien cupiditate magni do ut, consequuntur <br />
              </div>

              <div class="content1-sec-section pt-4">
                <img src={Line} />

                <div class="verifyitem pt-2">
                  <img src={greenicon} />
                  Fugiat suspendisse maxime excepteur cillum hic cum labore
                  cenas. Invent
                </div>
                <div class="verifyitem pt-2">
                  <img src={greenicon} />
                  Fugiat suspendisse maxime excepteur cillum hic cum labore
                  cenas. Invent
                </div>
                <div class="verifyitem pt-2">
                  <img src={greenicon} />
                  Fugiat suspendisse maxime excepteur cillum hic cum labore
                  cenas. Invent
                </div>
                <div class="twocardsimg">
                  <img class="grey370" src={grey370icon} />
                  <img class="grey370" src={grey370icon} />
                </div>
                <div class="paragraphs-data">
                  <p class="paragraph1">
                    Perferendis lacinia non, est distinctio ut eveniet, posuere
                    mus nostrum eget itaque, irure illo leo. Est! Numquam <br />{" "}
                    autem ipsa! Dolores eiusmod, impedit bibendum porro! Error!
                    Magna quia. Quia officia non? Lectus corporis
                  </p>
                  <p class="paragraph1 pt-4">
                    Perferendis, voluptatum. Exercitation justo aliquip?
                    Convallis ligula aptent aute ab? Sit necessitatibus error,
                    quaerat <br /> curae tristique tempore velit, nascetur ullam
                    metus molestie, etiam sapien cupiditate magni do ut,
                    consequuntur <br />
                    doloribus ea fusce recusandae eros, minim dolore magnis
                  </p>
                </div>
              </div>

              <div class="tworows-text">
                <div className="grid-item">
                  <img src={greenicon} />
                  Fugiat suspendisse maxime excepteu
                </div>
                <div className="grid-item">
                  <img src={greenicon} />
                  Fugiat suspendisse maxime excepteu
                </div>
                <div className="grid-item">
                  <img src={greenicon} />
                  Fugiat suspendisse maxime excepteu
                </div>
                <div className="grid-item">
                  <img src={greenicon} />
                  Fugiat suspendisse maxime excepteu
                </div>
                <div className="grid-item">
                  <img src={greenicon} />
                  Fugiat suspendisse maxime excepteu
                </div>
                <div className="grid-item">
                  <img src={greenicon} />
                  Fugiat suspendisse maxime excepteu
                </div>
              </div>

              <div>
                <p class="text50">
                  Perferendis lacinia non, est distinctio ut eveniet, posuere
                  mus nostrum eget itaque, irure illo leo. Est! Numquam <br />{" "}
                  autem ipsa! Dolores eiusmod, impedit bibendum porro! Error!
                  Magna quia. Quia officia non? Lectus corporis
                </p>
              </div>
            </div>
            <div
              id="content2"
              style={{ display: content === "Content 2" ? "block" : "none" }}
            >
              <div class="content1-title">What you’ll learn?</div>
              <div class="content1-sec-section pt-4">
                <img src={Line} />

                <div class="verifyitem pt-2">
                  <img src={greenicon} />
                  Fugiat suspendisse maxime excepteur cillum hic cum labore
                  cenas. Invent
                </div>
                <div class="verifyitem pt-2">
                  <img src={greenicon} />
                  Fugiat suspendisse maxime excepteur cillum hic cum labore
                  cenas. Invent
                </div>
                <div class="verifyitem pt-2">
                  <img src={greenicon} />
                  Fugiat suspendisse maxime excepteur cillum hic cum labore
                  cenas. Invent
                </div>
                <div class="content1-text">
                  Perferendis, voluptatum. Exercitation justo aliquip? Convallis ligula aptent aute ab? Sit <br /> necessitatibus error, quaerat curae tristique tempore velit, nascetur ullam metus seshy <br /> molestie, etiam sapien cupiditate magni do ut,  doloribus ea fusce  , minim dolore magnis
                </div>

                <div class="tworows-text2">
                  <div className="grid-item">
                    <img src={greenicon} />
                    Fugiat suspendisse maxime excepteu
                  </div>
                  <div className="grid-item">
                    <img src={greenicon} />
                    Fugiat suspendisse maxime excepteu
                  </div>
                  <div className="grid-item">
                    <img src={greenicon} />
                    Fugiat suspendisse maxime excepteu
                  </div>
                  <div className="grid-item">
                    <img src={greenicon} />
                    Fugiat suspendisse maxime excepteu
                  </div>
                  <div className="grid-item">
                    <img src={greenicon} />
                    Fugiat suspendisse maxime excepteu
                  </div>
                  <div className="grid-item">
                    <img src={greenicon} />
                    Fugiat suspendisse maxime excepteu
                  </div>
                </div>
                <div class="content1-title">Course Content</div>

              </div>

              <div class="accordion" id="accordionExample2">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne1">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      Introduction
                      <span class="sec-header-accord" style={{ fontWeight: "400" }}>  <span className="removedash"> &nbsp;|</span> 2 Lessons - 3min </span>

                    </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne1" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <div class="left-section-icon">
                        <img src={blackbook} />
                        <span>introduction</span>
                      </div>
                      <div class="right-section-icon">
                        <p>Preview <span class="lesson-time"> &nbsp;&nbsp; 00:59</span> </p>

                      </div>
                      <div class="left-section-icon">
                        <img src={blackbook} />
                        <span>introduction</span>
                      </div>
                      <div class="right-section-icon">
                        <p>Preview <span class="lesson-time"> &nbsp;&nbsp; 00:59</span> </p>

                      </div>

                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo2">
                    <button class="accordion-button " type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      Creating a Syllabus

                      <span style={{ fontWeight: "400" }}> <span className="removedash"> &nbsp;|</span> 6 Lessons - 57min </span>
                    </button>
                  </h2>
                  <div id="collapseTwo" class="accordion-collapse collapse show" aria-labelledby="headingTwo2" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <div class="left-section-icon">
                        <img src={blackbook} />
                        <span>introduction</span>
                      </div>
                      <div class="right-section-icon">
                        <p>Preview <span class="lesson-time"> &nbsp;&nbsp; 00:59</span> </p>

                      </div>
                      <div class="left-section-icon">
                        <img src={blackbook} />
                        <span>introduction</span>
                      </div>
                      <div class="right-section-icon">
                        <p>Preview <span class="lesson-time"> &nbsp;&nbsp; 00:59</span> </p>

                      </div>

                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div
              id="content3"
              style={{ display: content === "Content 3" ? "block" : "none" }}
            >
              <div class="row">
                <div className="col-md-5 col-12">
                  <div class="card111 mb-3 ">
                    <div class="row no-gutters">
                      <div class="col-md-4  col-4">
                        <div class="bg-rating">
                          <span>4.7</span>
                        </div>
                      </div>
                      <div class="col-md-8 col-8 firstcardcontent">
                        <div class="card111-body" style={{ marginLeft: "20px" }}>
                          <div className="stars">
                            <img src={stars} />
                            <img src={stars} />
                            <img src={stars} />
                            <img src={stars} />
                            <img src={stars} />
                          </div>
                          <p class="card111-text">From 1.5 Reviews</p>
                        </div>


                      </div>
                    </div>

                  </div>
                </div>
                <div className="col-md-7 reviews-grid">
                  <div class="review-bar" >
                    <img src={stars} />
                    <span>5</span>
                    <div className="bar" style={{ backgroundColor: '#f0f0f0', marginBottom: '5px' }}>
                      <div className="fill" style={{ height: '100%' }}>

                      </div>

                    </div>
                    <div className="reviews">60%</div>

                  </div>
                  <div class="review-bar" >
                    <img src={stars} />
                    <span>5</span>
                    <div className="bar" style={{ backgroundColor: '#f0f0f0', marginBottom: '5px' }}>
                      <div className="fill" style={{ height: '100%' }}>

                      </div>

                    </div>
                    <div className="reviews">60%</div>

                  </div>
                  <div class="review-bar" >
                    <img src={stars} />
                    <span>5</span>
                    <div className="bar" style={{ backgroundColor: '#f0f0f0', marginBottom: '5px' }}>
                      <div className="fill" style={{ height: '100%' }}>

                      </div>

                    </div>
                    <div className="reviews">60%</div>

                  </div>
                  <div class="review-bar" >
                    <img src={stars} />
                    <span>5</span>
                    <div className="bar" style={{ backgroundColor: '#f0f0f0', marginBottom: '5px' }}>
                      <div className="fill" style={{ height: '100%' }}>

                      </div>

                    </div>
                    <div className="reviews">60%</div>

                  </div>
                  <div class="review-bar" >
                    <img src={stars} />
                    <span>5</span>
                    <div className="bar" style={{ backgroundColor: '#f0f0f0', marginBottom: '5px' }}>
                      <div className="fill" style={{ height: '100%' }}>

                      </div>

                    </div>
                    <div className="reviews">60%</div>

                  </div>
                </div>

              </div>


              <div class="review-list">
                <ul>
                  <li
                    onClick={() => changeContentreview("All-btn-Review", "tag10")}
                    className={`List-item1 ${activeTagreview === "tag10" ? "active" : ""}`}

                  >
                    <button class="all-review-btnn">
                      All
                    </button>
                  </li>
                  <li
                    onClick={() => changeContentreview("5-start-btn", "tag11")}
                    className={`List-item1 ${activeTagreview === "tag11" ? "active" : ""}`}

                  >
                    <a>5 Stars</a>
                  </li>

                  <li onClick={() => changeContentreview("4-star-btn", "tag12")}
                    className={`List-item1 ${activeTagreview === "tag12" ? "active" : ""}`}
                  >
                    <a>4 Stars</a>
                  </li>
                  <li onClick={() => changeContentreview("3-star-btn", "tag13")}
                    className={`List-item1 ${activeTagreview === "tag13" ? "active" : ""}`}
                  >
                    <a>3 Stars</a>
                  </li>
                  <li onClick={() => changeContentreview("2-star-btn", "tag14")}
                    className={`List-item1 ${activeTagreview === "tag14" ? "active" : ""}`}
                  >
                    <a>2 Stars</a>
                  </li>
                  <li onClick={() => changeContentreview("1-star-btn", "tag15")}
                    className={`List-item1 ${activeTagreview === "tag15" ? "active" : ""}`}
                  >
                    <a>1 Star</a>
                  </li>
                </ul>
              </div>
              <div class="mb-3" id="all-review-content" style={{ display: contentreview === "AllReview" ? "block" : "none" }}>
                <div className="single-review-container">
                  <div class="row ">
                    <div className="col-md-5 col-12">
                      <div class="card111">
                        <div class="row no-gutters">
                          <div class="col-md-4  col-4 pt-4">
                            <img src={reviewpic} class="review-img" alt="..." />
                          </div>
                          <div class="col-md-8 col-8 firstcardcontent">
                            <div class="card111-body ml-4" >
                              <p class="card111-text">Shady Abdo</p>
                              <div className="stars">
                                <img src={stars} />
                                <img src={stars} />
                                <img src={stars} />
                                <img src={stars} />
                                <img src={stars} />
                              </div>
                            </div>


                          </div>
                        </div>

                      </div>
                    </div>


                    <div className="col-md-7 col-ms-12 right-review-content">
                      <span>Amazing Course!</span>
                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been standard.</p>
                      <button> <span style={{ fontSize: "150%", color: "#009CE4", marginRight: "5px" }}>&hearts;</span>Like</button>

                    </div>
                  </div>
                </div>
                <div className="single-review-container mt-2">
                  <div class="row ">
                    <div className="col-md-5 col-12">
                      <div class="card111">
                        <div class="row no-gutters">
                          <div class="col-md-4  col-4 pt-4">
                            <img src={reviewpic} class="review-img" alt="..." />
                          </div>
                          <div class="col-md-8 col-8 firstcardcontent">
                            <div class="card111-body ml-4" >
                              <p class="card111-text">Shady Abdo</p>
                              <div className="stars">
                                <img src={stars} />
                                <img src={stars} />
                                <img src={stars} />
                                <img src={stars} />
                                <img src={stars} />
                              </div>
                            </div>


                          </div>
                        </div>

                      </div>
                    </div>


                    <div className="col-md-7 col-ms-12 right-review-content ">
                      <span>Amazing Course!</span>
                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been standard.</p>
                      <button> <span style={{ fontSize: "150%", color: "#009CE4", marginRight: "5px" }}>&hearts;</span>Like</button>

                    </div>
                  </div>
                </div>
                <div className="single-review-container mt-2">
                  <div class="row ">
                    <div className="col-md-5 col-12">
                      <div class="card111">
                        <div class="row no-gutters">
                          <div class="col-md-4  col-4 pt-4">
                            <img src={reviewpic} class="review-img" alt="..." />
                          </div>
                          <div class="col-md-8 col-8 firstcardcontent">
                            <div class="card111-body ml-4" >
                              <p class="card111-text">Shady Abdo</p>
                              <div className="stars">
                                <img src={stars} />
                                <img src={stars} />
                                <img src={stars} />
                                <img src={stars} />
                                <img src={stars} />
                              </div>
                            </div>


                          </div>
                        </div>

                      </div>
                    </div>


                    <div className="col-md-7 col-ms-12 right-review-content ">
                      <span>Amazing Course!</span>
                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been standard.</p>
                      <button> <span style={{ fontSize: "150%", color: "#009CE4", marginRight: "5px" }}>&hearts;</span>Like</button>

                    </div>
                  </div>
                </div>

                <div class="d-flex justify-content-center align-items-center pt-3">
                  <button className="write-review" onClick={toggleModel2}>
                    Write a Review
                  </button>
                </div>
              </div>
              <div id="5content" style={{ display: contentreview === "5Content" ? "block" : "none" }}>

                <span>sss</span>

              </div>
              <div id="4content" style={{ display: contentreview === "4Content" ? "block" : "none" }}>

              </div>
            </div>

          </div>






        </div>
      </div>

      <Coursesslider />
      <Footer />

      {Model && (
        <div className="model">
          <div class="overlay"></div>
          <div class="model-content">
            <img src={verify} />
            <h2>Added to cart successfully</h2>

            <div class="card-table mb-3">
              <div class="row">
                <div class="col-md-2 col-2 center-mob-view-model">
                  <img
                    src={productimg}
                    class="img-fluid rounded-start"
                    alt="..."
                  />
                </div>
                <div class="col-md-10 col-10">
                  <div class="card-body">
                    <p class="card-text-view" style={{ fontSize: "14px", width: "75%", marginTop: "-10px" }}>
                      How to learn JavaScript Lorem Ipsum is <br /> simply dummy text
                      of the printing and <br /> typesetting industry.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <Link to='/shoppingcart'>
              <button className="go-to-cart-btn">Go to Cart</button></Link>
            <button className="close-model" onClick={toggleModel}>
              <img src={xicon} />
            </button>
          </div>
        </div>
      )}
      {Model2 && (
        <div className="model2">
          <div class="overlay2"></div>
          <div class="model-content2">
            <img src={writereview} />
            <h2>Your Review</h2>

            <div className="star-rating">
              {[...Array(5)].map((_, index) => (
                <span
                  key={index}
                  className={index < rating ? 'star filled' : 'star'}
                  onClick={() => handleClick(index)}
                >
                  &#9733;
                </span>
              ))}
            </div>


            <div class="review-text-sec">

              <h5>Write a Review</h5>
              <form>
                <div class="form-group">
                  <textarea class="form-control" id="exampleFormControlTextarea2" rows="3"></textarea>
                </div>
              </form>
            </div>





            <Link to='/shoppingcart'>
              <button className="submit-review-btn">Submit Review</button></Link>
            <button className="close-model2" onClick={toggleModel2}>
              <img src={xicon} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
