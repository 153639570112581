import logo from './logo.svg';
import './App.css';
import Navbar from './Components/Navbar';
import Content from './Components/content';
import FilterBar from './Components/filter';
import SearchComponent from './Components/search';
import store from './Redux/Store/store';
import Home from './Components/Home';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from './Components/footer';
import Contactus from './Components/contactus';
import Coursespage from './Components/coursespage';
import Softwarepage from './Components/Softwarepage';
import Coursedetails from './Components/coursedetails';
import Loginpage from './Components/Loginpage';
import ShoppingCart from './Components/Shoppingcart';
import Searchresult from './Components/Searchresult';
import Signup from './Components/Signup';
import Checkout from './Components/Checkout';
import Forgetpassword from './Components/Forgetpassword';


function App() {
  return (
    <Provider store={store}>
    <>

      <Routes>
        
        <Route path="/" element={ <Home />}> </Route>
        <Route path="/contactus" element={ <Contactus />}> </Route>
        <Route path="/allcourses" element={ <Coursespage />}> </Route>
        <Route path="/softwareengineer" element={ <Softwarepage />}> </Route>
        <Route path="/coursedetails" element={ <Coursedetails />}> </Route>
        <Route path="/shoppingcart" element={ <ShoppingCart />}> </Route>
        <Route path="/loginpage" element={ <Loginpage />}> </Route>
        <Route path="/searchresult" element={ <Searchresult />}> </Route>
        <Route path="/signuppage" element={ <Signup />}> </Route>
        <Route path="/checkout" element={ <Checkout />}> </Route>
        <Route path="/forgetpassword" element={ <Forgetpassword />}> </Route>

      </Routes>
</>

     </Provider>
  );
}

export default App;
