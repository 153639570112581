import React from 'react'
import singlecoursepic from "/webapps/lms_react/src/assets/pic2.png";
import singlecoursepic1 from "/webapps/lms_react/src/assets/Mask Group 37@2x.png";
import techerpic from "/webapps/lms_react/src/assets/pic3.png";
import lessonicon from "/webapps/lms_react/src/assets/Icon awesome-book-open.png";
import { Link } from 'react-router-dom';
import secimgcard from "/webapps/lms_react/src/assets/Mask Group 372.png";
import thirdcardimg from "/webapps/lms_react/src/assets/Mask Group 37sss.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import 'swiper/css/navigation';
import { Autoplay ,Navigation, Pagination } from "swiper/modules";
import "swiper/css/pagination";
import "./style/Firstslider.css"
import "./style/CoursesSlider.css"
import Line from "/webapps/lms_react/src/assets/Line.svg";

import combinedshape from "/webapps/lms_react/src/assets/Combined Shape.png"
export default function Coursesslider() {
  return (
    <div className='More-Courses'>
        
        <div class="pre-text2">
          <h6>
            <img src={Line} style={{ marginRight: "10px" }} />
                More course
          </h6>
          <h3>
            More courses from Best Authors
          </h3>
        </div>





   <div class="container centerslidermob ">

    <Swiper  
  
      loop={true}
    //   autoplay={{
    //       delay: 2000,
    //       disableOnInteraction: false
    //   }}
      slidesPerView={1}
      spaceBetween={40}
      centeredSlides={true}
      //  pagination={{
      //    clickable: true,
      //  }}
       navigation={{
        nextEl: '.swiper-button-next3',
        prevEl: '.swiper-button-prev3',
       }}
        modules={[Autoplay, Pagination, Navigation]}
      className="mySwiper pb-5"
      breakpoints={{
        // when window width is >= 576px (mobile)
        576: {
          slidesPerView: 1, // Adjust the number of slides per view for mobile
          spaceBetween: 10 // Adjust the space between slides for mobile
        },
        // when window width is >= 768px (tablet)
        768: {
          slidesPerView: 1, // Adjust the number of slides per view for tablet
          spaceBetween: 50 // Adjust the space between slides for tablet
        },
        // when window width is >= 1024px (desktop)
        1024: {
          slidesPerView: 3, // Keep the original number of slides per view for desktop
          spaceBetween: 50 // Keep the original space between slides for desktop
        }
      }}
    >
      <SwiperSlide >
      <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>
        </SwiperSlide>
        <SwiperSlide>
        <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div> 
        </SwiperSlide>
        <SwiperSlide>
        <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div> 
        </SwiperSlide>
        <SwiperSlide>
        <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>  
        </SwiperSlide>
        <SwiperSlide>
        <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>
        </SwiperSlide>
      <SwiperSlide>
      <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>    
        </SwiperSlide>   
           <div class='navigation3'>
         <div class="swiper-button-prev3"></div>

      <div class="swiper-button-next3"></div> 
      </div>
    </Swiper>
  </div> 










    </div>
  )
}
