import React, { useEffect, useState } from "react";
import "./style/Searchresult.css";
import Navbar from "./Navbar";
import singlecoursepic from "/webapps/lms_react/src/assets/pic2.png";
import singlecoursepic1 from "/webapps/lms_react/src/assets/Mask Group 37@2x.png";
import techerpic from "/webapps/lms_react/src/assets/pic3.png";
import lessonicon from "/webapps/lms_react/src/assets/Icon awesome-book-open.png";
import filterimg from "/webapps/lms_react/src/assets/filter_icon_183755.png";
import { Link } from 'react-router-dom';
import Footer from '/webapps/lms_react/src/Components/footer';

export default function Searchresult() {
  const [freeChecked, setFreeChecked] = useState(false);
  const [paidChecked, setPaidChecked] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [courses, setCourses] = useState([]);
  const [price, setPrice] = useState(null);
  const [level, setLevel] = useState(null);
  const [duration, setDuration] = useState(null);

  const toggleFilter = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const params = new URLSearchParams();
        if (freeChecked) {
          params.append('price', 'free');
        }
        if (paidChecked) {
          params.append('price', 'paid');
        }

        const response = await fetch(`http://localhost:4000/allcourses?${params.toString()}`);
        const data = await response.json();
        setCourses(data);
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    fetchCourses();
  }, [freeChecked, paidChecked]);

  const handleFreeCheckboxChange = () => {
    setFreeChecked(!freeChecked);
    if (!freeChecked) {
      setPaidChecked(false);
    }
  }

  const handlePaidCheckboxChange = () => {
    setPaidChecked(!paidChecked);
    if (!paidChecked) {
      setFreeChecked(false);
    }
  };
  const handlePriceChange = (value) => {
    setPrice(value);
  };

  const handleLevelChange = (value) => {
    setLevel(value);
  };

  const handleDurationChange = (value) => {
    setDuration(value);
  };
  return (
    <div>
      <Navbar />
      <div className="blue-ad">
        <h6>
          Lorem Epsom is dummy text our entire library of in-demand skills
          training. <a href="">WEEKEND OFFER</a>
        </h6>
      </div>

      <div className="container search-sec ">
        <div className="search-titles-sec">
          <h5>Search Results For “Design”</h5>
          <p>
            Home / Courses <span> / Search Results For “Design”</span>
          </p>

        </div>


        <div className="drop-sec">

          <div class="dropdown1">
            <a
              class="btn btn-secondary dropdown1-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Sort by Relevance{" "}
            </a>

            <ul class="dropdown-menu">
              <li>
                <a class="dropdown-item" href="#">
                  Filter
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="#">
                  Level
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="#">
                  Category
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="mobfilter-sec">
          <button class="btn btn-primary bottom-canvas-block" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom">
            <img src={filterimg} class="wid-of-img-filter" ></img>
            Filter by
          </button>

          <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel" style={{ width: "100%" }}>
            <div class="offcanvas-header" id="bottomcanv">
              <h5 class="offcanvas-title" id="offcanvasBottomLabel"><img src={filterimg} class="wid-of-img-filter" ></img>
                Filter by </h5>
              <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body small">
              <div className="price-filter">

                <div className="accordion d-sm-block" id="accordionPanelsStayOpenExample" style={{ alignItems: "start" }}>
                  <div class="accordion pt-2" id="accordionPanelsStayOpenExample" style={{ alignItems: "start", width: "100%" }}>
                    <div class="accordion-item" style={{ width: "100%" }}>
                      <h2 class="accordion-header " id="panelsStayOpen-Four">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="true" aria-controls="panelsStayOpen-collapseFout">
                          All Categories

                        </button>
                      </h2>
                      <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingFour">
                        <div class="accordion-body" style={{ textAlign: "start" }}>
                          <ul class="allcategories-list">
                            <li class="allcategories-item">
                              <p>Computer Science <span>(56)</span></p>
                            </li>
                            <li class="allcategories-item">
                              <p>Health & Fitness <span>(56)</span></p>
                            </li>
                            <li class="allcategories-item">
                              <p>Software Engineer <span>(38)</span></p>
                            </li>
                            <li class="allcategories-item">
                              <p>Architecture <span>(56)</span></p>
                            </li>
                            <li class="allcategories-item">
                              <p>Art & Design <span>(56)</span></p>
                            </li>
                            <li class="allcategories-item">
                              <p>Adobe designing <span>(56)</span></p>
                            </li>
                            <li class="allcategories-item">
                              <p>Analysis of Algorithms  <span>(56)</span></p>
                            </li>
                            <li class="allcategories-item">
                              <p>History Archaeologi  <span>(56)</span></p>
                            </li>


                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item" style={{ width: "100%" }}>
                      <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                          Price
                        </button>
                      </h2>
                      <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
                        <div class="accordion-body" style={{ textAlign: "start" }}>
                          <label><input type="checkbox" checked={freeChecked} onChange={handleFreeCheckboxChange} value="Free" id="Free" /> Free</label><br />
                          <label><input type="checkbox" checked={paidChecked} onChange={handlePaidCheckboxChange} value="Paid" id="Paid" /> Paid</label>      </div>
                      </div>
                    </div>
                    <div class="accordion-item" style={{ width: "100%" }}>
                      <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                        <button class="accordion-button " type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                          Level
                        </button>
                      </h2>
                      <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                        <div class="accordion-body" style={{ textAlign: "start" }}>
                          <label><input type="checkbox" checked={level === 'Beginner'} onChange={() => handleLevelChange('Beginner')} value="Beginner" id="Beginner" /> Beginner</label><br />
                          <label><input type="checkbox" checked={level === 'Intermediate'} onChange={() => handleLevelChange('Intermediate')} value="Intermediate" id="Intermediate" /> Intermediate</label><br />
                          <label><input type="checkbox" checked={level === 'Advanced'} onChange={() => handleLevelChange('Advanced')} value="Advanced" id="Advanced" /> Advanced</label>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item" style={{ width: "100%" }}>
                      <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                        <button class="accordion-button " type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                          Duration
                        </button>
                      </h2>
                      <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                        <div class="accordion-body" style={{ textAlign: "start" }}>
                          <label><input type="checkbox" checked={duration === 'Hours'} onChange={() => handleDurationChange('Hours')} value="Hours" id="Hours" /> Hours</label><br />
                          <label><input type="checkbox" checked={duration === 'Days'} onChange={() => handleDurationChange('Days')} value="Days" id="Days" /> Days</label><br />
                          <label><input type="checkbox" checked={duration === 'Weeks'} onChange={() => handleDurationChange('Weeks')} value="Weeks" id="Weeks" /> Weeks</label><br />
                          <label><input type="checkbox" checked={duration === 'Month'} onChange={() => handleDurationChange('Month')} value="Month" id="Month" /> Month</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
        {/* <div class="dropdown1">
            <a
              class="btn btn-secondary dropdown1-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Sort by Relevance{" "}
            </a>

            <ul class="dropdown-menu">
              <li>
                <a class="dropdown-item" href="#">
                  Filter
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="#">
                  Level
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="#">
                  Category
                </a>
              </li>
            </ul>
          </div>  */}
        {/* <div className="row pt-4 whole-filter-container">
          <div className="col-4">
            <div className="container allcategory-sec">
              <div className="allcategory-sec-title">
                All Categories
              </div>
              <ul class="allcategories-list">
                <li class="allcategories-item">
                  <p>Computer Science <span>(56)</span></p>
                </li>
                <li class="allcategories-item">
                  <p>Health & Fitness <span>(56)</span></p>
                </li>
                <li class="allcategories-item">
                  <p>Software Engineer <span>(38)</span></p>
                </li>
                <li class="allcategories-item">
                  <p>Architecture <span>(56)</span></p>
                </li>
                <li class="allcategories-item">
                  <p>Art & Design <span>(56)</span></p>
                </li>
                <li class="allcategories-item">
                  <p>Adobe designing <span>(56)</span></p>
                </li>
                <li class="allcategories-item">
                  <p>Analysis of Algorithms  <span>(56)</span></p>
                </li>
                <li class="allcategories-item">
                  <p>History Archaeologi  <span>(56)</span></p>
                </li>


              </ul>

            </div>
          </div>
          <div className="col-5">
            <div className="price-filter">

              <div class="accordion accordion-flush" id="accordionPanelsStayOpenExample" style={{ alignItems: "start" }}>
                <div class="accordion-item border-0" style={{ width: "268px" }}>
                  <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                      Price
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                    <div class="accordion-body border-0" style={{ textAlign: "start" }}>
                      <label><input type="checkbox" checked={freeChecked} onChange={handleFreeCheckboxChange} value="Free" id="Free" /> Free</label><br />
                      <label><input type="checkbox" checked={paidChecked} onChange={handlePaidCheckboxChange} value="Paid" id="Paid" /> Paid</label>      </div>
                  </div>
                </div>
                <div class="accordion-item border-0" style={{ width: "268px" }}>
                  <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                    <button class="accordion-button " type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                      Level
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingTwo">
                    <div class="accordion-body" style={{ textAlign: "start" }}>
                      <label><input type="checkbox" checked={level === 'Beginner'} onChange={() => handleLevelChange('Beginner')} value="Beginner" id="Beginner" /> Beginner</label><br />
                      <label><input type="checkbox" checked={level === 'Intermediate'} onChange={() => handleLevelChange('Intermediate')} value="Intermediate" id="Intermediate" /> Intermediate</label><br />
                      <label><input type="checkbox" checked={level === 'Advanced'} onChange={() => handleLevelChange('Advanced')} value="Advanced" id="Advanced" /> Advanced</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className="col-3">
            <div class="accordion accordion-flush" id="accordionPanelsStayOpenExample" style={{ alignItems: "start" }}>
              <div class="accordion-item" style={{ width: "268px" }}>
                <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                  <button class="accordion-button " type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                    Duration
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingThree">
                  <div class="accordion-body" style={{ textAlign: "start" }}>
                    <label><input type="checkbox" checked={duration === 'Hours'} onChange={() => handleDurationChange('Hours')} value="Hours" id="Hours" /> Hours</label><br />
                    <label><input type="checkbox" checked={duration === 'Days'} onChange={() => handleDurationChange('Days')} value="Days" id="Days" /> Days</label><br />
                    <label><input type="checkbox" checked={duration === 'Weeks'} onChange={() => handleDurationChange('Weeks')} value="Weeks" id="Weeks" /> Weeks</label><br />
                    <label><input type="checkbox" checked={duration === 'Month'} onChange={() => handleDurationChange('Month')} value="Month" id="Month" /> Month</label>
                  </div>
                </div>
              </div>
            </div>

          <div className="btn-sec">
          <button class="filter-btn">Filter</button>
          <button className="reset-filter-btn">Reset Filter</button>

          </div>


          </div>
        </div> */}
        <div className="whole-flex pt-2 ">


          <div className="price-filter">

            <div className={`accordion d-sm-block ${isOpen ? 'show' : 'collapse'}`} id="accordionPanelsStayOpenExample" style={{ alignItems: "start" }}>
              <div class="accordion" id="accordionPanelsStayOpenExample" style={{ alignItems: "start" }}>
                <div class="accordion-item" style={{ width: "268px" }}>
                  <h2 class="accordion-header" id="panelsStayOpen-Four">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="true" aria-controls="panelsStayOpen-collapseFout">
                      All Categories

                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingFour">
                    <div class="accordion-body" style={{ textAlign: "start" }}>
                      <ul class="allcategories-list">
                        <li class="allcategories-item">
                          <p>Computer Science <span>(56)</span></p>
                        </li>
                        <li class="allcategories-item">
                          <p>Health & Fitness <span>(56)</span></p>
                        </li>
                        <li class="allcategories-item">
                          <p>Software Engineer <span>(38)</span></p>
                        </li>
                        <li class="allcategories-item">
                          <p>Architecture <span>(56)</span></p>
                        </li>
                        <li class="allcategories-item">
                          <p>Art & Design <span>(56)</span></p>
                        </li>
                        <li class="allcategories-item">
                          <p>Adobe designing <span>(56)</span></p>
                        </li>
                        <li class="allcategories-item">
                          <p>Analysis of Algorithms  <span>(56)</span></p>
                        </li>
                        <li class="allcategories-item">
                          <p>History Archaeologi  <span>(56)</span></p>
                        </li>


                      </ul>
                    </div>
                  </div>
                </div>
                <div class="accordion-item" style={{ width: "268px" }}>
                  <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                      Price
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                    <div class="accordion-body" style={{ textAlign: "start" }}>
                      <label><input type="checkbox" checked={freeChecked} onChange={handleFreeCheckboxChange} value="Free" id="Free" /> Free</label><br />
                      <label><input type="checkbox" checked={paidChecked} onChange={handlePaidCheckboxChange} value="Paid" id="Paid" /> Paid</label>      </div>
                  </div>
                </div>
                <div class="accordion-item" style={{ width: "268px" }}>
                  <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                    <button class="accordion-button " type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                      Level
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingTwo">
                    <div class="accordion-body" style={{ textAlign: "start" }}>
                      <label><input type="checkbox" checked={level === 'Beginner'} onChange={() => handleLevelChange('Beginner')} value="Beginner" id="Beginner" /> Beginner</label><br />
                      <label><input type="checkbox" checked={level === 'Intermediate'} onChange={() => handleLevelChange('Intermediate')} value="Intermediate" id="Intermediate" /> Intermediate</label><br />
                      <label><input type="checkbox" checked={level === 'Advanced'} onChange={() => handleLevelChange('Advanced')} value="Advanced" id="Advanced" /> Advanced</label>
                    </div>
                  </div>
                </div>
                <div class="accordion-item" style={{ width: "268px" }}>
                  <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                    <button class="accordion-button " type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                      Duration
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingThree">
                    <div class="accordion-body" style={{ textAlign: "start" }}>
                      <label><input type="checkbox" checked={duration === 'Hours'} onChange={() => handleDurationChange('Hours')} value="Hours" id="Hours" /> Hours</label><br />
                      <label><input type="checkbox" checked={duration === 'Days'} onChange={() => handleDurationChange('Days')} value="Days" id="Days" /> Days</label><br />
                      <label><input type="checkbox" checked={duration === 'Weeks'} onChange={() => handleDurationChange('Weeks')} value="Weeks" id="Weeks" /> Weeks</label><br />
                      <label><input type="checkbox" checked={duration === 'Month'} onChange={() => handleDurationChange('Month')} value="Month" id="Month" /> Month</label>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
          <div class="search-cards">
            <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>
            </div>

            <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec_new">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>
            </div>
            <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>
            </div>
            <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>
            </div>
            <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>
            </div>
            <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>

          </div>
        </div>

      </div>

      <Footer />
    </div>
  );
}
