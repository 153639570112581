import React, { useState } from 'react'
import singlecoursepic from "/webapps/lms_react/src/assets/pic2.png";
import singlecoursepic1 from "/webapps/lms_react/src/assets/Mask Group 37@2x.png";
import techerpic from "/webapps/lms_react/src/assets/pic3.png";
import lessonicon from "/webapps/lms_react/src/assets/Icon awesome-book-open.png";
import { Link } from 'react-router-dom';
import secimgcard from "/webapps/lms_react/src/assets/Mask Group 372.png";
import thirdcardimg from "/webapps/lms_react/src/assets/Mask Group 37sss.png";
import "./style/allcategory.css";


export default function Allcategoryhome() {
    const [content, setContent] = useState("AllContent");
    const [activeTag, setActiveTag] = useState("tag1");
    const changeContent = (id, tagname) => {
        switch (id) {
            case "all-btn":
                setContent("AllContent");
                break;
            case "science-btn":
                setContent("ScienceContent");
                break;
            case "design-btn":
                setContent("DesignContent");
                break;
            case "food-btn":
                setContent("FoodContent");
                break;
            case "tech-btn":
                setContent("TechContent");
                break;
            case "music-btn":
                setContent("MusicContent");
                break;
            case "medicine-btn":
                setContent("MedicineContent");
                break;

            default:
                setContent("");
                break;
        }
        setActiveTag(tagname);
    };
    return (
        <div>
            <div className="container">
                <div class="course-sec-mid-content">
                    <h6 class="course-section-title">COURSES</h6>

                    <h5 class="course-section-title2">Popular Subjects</h5>

                    <div class="menu-list">
                        <ul>
                            <li
                                onClick={() => changeContent("all-btn", "tag1")}
                                className={`List-item3 ${activeTag === "tag1" ? "active" : ""}`}

                            >
                                <button  class="all">
                                    All
                                </button>
                            </li>
                            <li
                                onClick={() => changeContent("science-btn", "tag2")}
                                className={`List-item3 ${activeTag === "tag2" ? "active" : ""}`}

                            >
                                <a>Science</a>
                            </li>
                            <li onClick={() => changeContent("design-btn", "tag3")}
                            className={`List-item3 ${activeTag === "tag3" ? "active" : ""}`}

                            >
                                <a>Design</a>
                            </li>
                            <li onClick={() => changeContent("food-btn", "tag4")}
                            className={`List-item3 ${activeTag === "tag4" ? "active" : ""}`}
                            >
                                <a>Food</a>
                            </li>
                            <li onClick={() => changeContent("tech-btn", "tag5")}
                            className={`List-item3 ${activeTag === "tag5" ? "active" : ""}`}
                            >
                                <a>Tech</a>
                            </li>
                            <li onClick={() => changeContent("music-btn", "tag6")}
                            className={`List-item3 ${activeTag === "tag6" ? "active" : ""}`}
                            >
                                <a>Music</a>
                            </li>
                            <li onClick={() => changeContent("medicine-btn", "tag7")} style={{width:"50%"}}
                            className={`List-item3 ${activeTag === "tag7" ? "active" : ""}`}
                            >
                                <a>Medicine</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div id="content1" style={{ display: content === "AllContent" ? "block" : "none" }}>
                <div class="list_of_cards">
                <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>
            <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>
            <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>
            <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>
                </div>

                <div class="loadmore-btn">
                  <Link to="/allcourses">
                    <button class="load-more-btn">Load more</button></Link>
                </div>
            </div>
            <div id="content2" style={{ display: content === "ScienceContent" ? "block" : "none" }}>
                <div class="list_of_cards">
                <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>
            <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>
            <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>
            <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>
                </div>

                <div class="loadmore-btn">
                  <Link to="/allcourses">
                    <button class="load-more-btn">Load more</button></Link>
                </div>
            </div>
            <div id="content3" style={{ display: content === "DesignContent" ? "block" : "none" }}>
            <div class="list_of_cards">
                <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>
            <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>
            <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>
            <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>
                </div>

                <div class="loadmore-btn">
                  <Link to="/allcourses">
                    <button class="load-more-btn">Load more</button></Link>
                </div>
            </div>
            <div id="content4" style={{ display: content === "FoodContent" ? "block" : "none" }}>
            <div class="list_of_cards">
                <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>
            <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>
            <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>
            <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>
                </div>

                <div class="loadmore-btn">
                  <Link to="/allcourses">
                    <button class="load-more-btn">Load more</button></Link>
                </div>
            </div>
            <div id="content5" style={{ display: content === "TechContent" ? "block" : "none" }}>
            <div class="list_of_cards">
                <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>
            <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>
            <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>
            <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>
                </div>
                <div class="loadmore-btn">
                  <Link to="/allcourses">
                    <button class="load-more-btn">Load more</button></Link>
                </div>
            </div>
            <div id="content6" style={{ display: content === "MusicContent" ? "block" : "none" }}>
            <div class="list_of_cards">
                <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>
            <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>
            <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>
            <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>
                </div>

                <div class="loadmore-btn">
                  <Link to="/allcourses">
                    <button class="load-more-btn">Load more</button></Link>
                </div>
            </div>
            <div id="content7" style={{ display: content === "MedicineContent" ? "block" : "none" }}>
            <div class="list_of_cards">
                <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>
            <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>
            <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>
            <div class="card-new-updated">
              <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                <img src={singlecoursepic1} class="card-new-img2" />
              </Link>
              {/* <div class="card-level">
                  <p>Beginners</p>
                </div>
                <div class="card-numbers_hours">
                  <p>30 <span>Hours</span></p>
                </div> */}
              <div className="techersec d-flex">
                <div class="tech-img-sec">
                  <img src={techerpic} class="circled-techer-img2" />
                </div>
                <div className="tech-img-name">
                  <p class="nameoftecher">Ahmed Ali</p>
                </div>
              </div>
              <div class="card-body-updated">
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>

                  <h5 class="card-title-new2">Web designing Development</h5>
                </Link>
                <p class="card-text-new2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’.</p>
                <Link to="/coursedetails" style={{ textDecorationColor: "none", textDecorationLine: "none", textDecorationStyle: "none" }}>
                  <button class="card-new-btn2">Learn More</button>
                </Link>
                <div class="pt-3">
                  <p class="lessons_number_new">
                    <img src={lessonicon} />
                    10 Lessons{" "}
                    <span class="price_btn_new">Price:<span>299$</span></span>
                  </p>
                </div>
              </div>






              {/* {courses.map(course => (
        <div key={course.id} className="card369">
          <img src={course.image} className="card-img-top" alt={course.name} />
          <div className="card-category2">
            <p>{course.category}</p>
          </div> 
          <div className="card-body">
            <h5 className="card-title mb-0">{course.name}</h5>
            <p className="card-text" style={{ color: "#333333" }}>{course.author}</p>
            <div>
              <p className="lessons_number2">
                10 Lessons <button className="price_btn3">{course.price}$</button>
              </p>
            </div>
          </div>
        </div>
      ))} */}
            </div>
                </div>

                <div class="loadmore-btn">
                  <Link to="/allcourses">
                    <button class="load-more-btn">Load more</button></Link>
                </div>
            </div>
            </div>

        </div>
    )
}
