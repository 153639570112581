import React from "react";
import "./style/contactform.css";
import landinglogo from "/webapps/lms_react/src/assets/Group 190.png";
import combinedshape from "/webapps/lms_react/src/assets/Combined Shape.png"
import Line from "/webapps/lms_react/src/assets/Line.svg";

export default function Contactform() {
  return (
    <div className="container wrappper2">
      <div class="titles pt-5 ">
        <img src={Line} />
        <span>  What's update now</span>
        <h3>Have any questions? Get in touch</h3>
      </div>

      <div class="Form-cotainer">
        <img src={landinglogo} class="landing55" />

        <div class="form">
          <form>
            <div class="inputField">
              <input
                type="name"
                name="name"
                id="name"
                placeholder="Your Name"
                autocomplete="on"
                required
              />
              <span class="valid_info_name"></span>
            </div>

            <div class="inputField">
              <input
                type="Email"
                name="email"
                id="email"
                placeholder="Your Email"
                required=""
              />
              <span class="valid_info_email"></span>
            </div>

            {/* <div class="selectField">
              <select id="select">
                <option>Select</option>
                <option>Service-One</option>
                <option>Service-Two</option>
              </select>
            </div>
            <div class="selectField">
              <select id="select">
                <option>Select</option>
                <option>Service-One</option>
                <option>Service-Two</option>
              </select>
            </div> */}

            <div class="inputField">
              <textarea
                name="message"
                id="message"
                placeholder="Your Message"
              ></textarea>
              <span class="valid_info_message"></span>
            </div>

            <div class="send-sec-btn" >
              <button
                type="submit"
                class="sendbtn  "
                disabled
              >
                Send
              </button>
            </div>
          </form>
        </div>
        <img src={combinedshape} style={{ height: "100%" }} class="landing33" />
      </div>
    </div>
  );
}
