import React, { useState } from "react";
import "./style/Login.css";
import loginimg from "/webapps/lms_react/src/assets/Sign In Vec.svg";
import logo from "/webapps/lms_react/src/assets/Group 185.png";
import { Link } from "react-router-dom";
import Footer from "./footer";

export default function Forgetpassword() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  
  return (
    <>
      <div class="container card-login ">
      <Link to="/"><img src={logo} class="logo55"/></Link>
        <div class="row ">
          <div class="col-md-6">
            <img src={loginimg} class="Login-card-img" alt="..." />
          </div>
          <div class="col-md-6">
            <div class="card-login-body">
              <div class="logo-img-container">
              <Link to="/"><img src={logo} class="logo33" /></Link>
            </div>
              <h5 class="card-title5">Forget Password</h5>
              <h5 class="card-title6"> We'll send you a link to reset your password.</h5>
              <form  >

                <div class="form-group100">
                  <label for="exampleInputEmail1">Email</label>
                  <input
                    type="email"
                    class="form-control"
                    value={email}
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="name@email.com" 
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  
                </div>
             
              
                <div className=" login-btn-section">
                <button type="submit" class="Login-btn">
                  Reset Password
                </button>

                </div>
                
              </form>
              {message && <p>{message}</p>}

            </div>
          </div>
        </div>
      </div>
      <Footer/>
      </>
  );
}
