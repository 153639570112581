import React from 'react'
import "./style/signup.css";
import loginimg from "/webapps/lms_react/src/assets/Sign In Vec.svg";
import logo from "/webapps/lms_react/src/assets/Logo-02.png";
import Footer from './footer';
import { Link } from 'react-router-dom';

export default function Signup() {
  return (
    <div>
        <div class="container card-login ">
          <div class="whole-flexing-signup ">
            <div class="logo-signup-section">
              <img src={loginimg} class="Login-card-img" alt="..." />
            </div>
            <div class="data-signup-section pb-4">
              <div class="card-login-body">
                <div class="logo-img-container">
                <Link to="/"><img src={logo} class="rak-logo" /></Link>
                </div>
                <h5 class="card-title5">Create Account</h5>
                <h5 class="card-title6" style={{ fontSize: "21px" }}>Don't have an account yet ? Register here !</h5>
                <form   >
                  <div className="row">
                  <div class="form-group101 col-md-8">
                    <label for="exampleInputEmail1">First Name</label>
                    <input
                      type="email"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="First Name"
                      required

                    />

                  </div>
                  <div class="form-group101 col-md-4">
                    <label for="exampleInputEmail1">Last Name</label>
                    <input
                      type="email"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Last Name"
                      required

                    />

                  </div>
                  </div>
                  <div className="row">
                  <div class="form-group101 col-md-8">
                    <label for="exampleInputEmail1">Email</label>
                    <input
                      type="email"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter email"
                      required
                    />

                  </div>
                  <div class="form-group101 col-md-4">
                    <label for="exampleInputEmail1">Phone</label>
                    <input
                      type="number"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Phone"
                      required

                    />

                  </div>
                  </div>

                  <div class="select-country">
                  <label for="exampleInputEmail1">Country</label>

                  <select class="form-select form-select-lg mb-3" aria-label=".form-select-lg example">
                    <option selected>Select Country</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                  </div>

                  <div className="row">
                  <div class="form-group101 col-md-8">
                    <label for="exampleInputPassword1">Password</label>
                    <input
                      type="password"
                      required
                      class="form-control"
                      id="exampleInputPassword1"
                      placeholder="Password"
                    />
                  </div>
                  <div class="form-group101 col-md-4">
                    <label for="exampleInputPassword1">Confirm Password</label>
                    <input
                      type="password"
                      required
                      class="form-control"
                      id="exampleInputPassword1"
                      placeholder="Password"
                    />
                  </div>
                  </div>


                  <div className=" login-btn-section">
                    <button type="submit" class="register-btn">
                      Register
                    </button>

                  </div>

                </form>

              </div>
            </div>
          </div>
        </div>

        <Footer/>
    </div>
  )
}

