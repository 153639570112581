import React, { useEffect, useState } from "react";
import contactusbanner from "/webapps/lms_react/src/assets/Group 200.png";
import "./style/contactus.css";
import icon from "/webapps/lms_react/src/assets/Icon.png";
import icon2 from "/webapps/lms_react/src/assets/Icon2.png";
import icon3 from "/webapps/lms_react/src/assets/Icon3.png";
import Contactform from "./Contactform";
import Ask from "./ask";
import Navbar from "./Navbar";
import Footer from "./footer";
import mob20 from "/webapps/lms_react/src/assets/Group 199.png";
import { Link } from "react-router-dom";

export default function Contactus() {

  const [users, setUsers] = useState([]);
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch('http://localhost:4000/all');
        if (!response.ok) {
          throw new Error('Failed to fetch users');
        }
        const data = await response.json();
        console.log('Fetched data:', data);
        setUsers(data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const firstName = formData.get('firstName');
    const lastName = formData.get('lastName');
    const age = formData.get('age');

    try {
      const response = await fetch('http://localhost:4000/hehehehehe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ firstName, lastName, age })
      });
      const data = await response.json();
      console.log(data); // Log the newly created user
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (


    <div>
      {/* <h1>User List</h1>
      <ul>
        {users.map(user => (
          <li style={{color:"red"}} key={user.id}>{user.firstName } {user.lastName}  {user.age}</li>
        ))}
      </ul>
<form onSubmit={handleSubmit}>
      <label>
        First Name:
        <input
          type="text"
          name="firstName"
        />
      </label>
      <label>
        Last Name:
        <input
          type="text"
          name="lastName"
        />
      </label>
      <label>
        Age:
        <input
          type="number"
          name="age"
        />
      </label>
      <button type="submit">Create User</button>
    </form> */}

      <Navbar />
      <div className="banner-container">
      <picture>
      <source className="imgcontactus" media="(max-width:767px)" srcset={mob20} />
      <source className="imgcontactus" src={mob20} media="(min-width: 768px) and (max-width: 1023px)"  />
      <source media="(min-width: 1024px)" src={contactusbanner} />
      <img className="imgcontactus" src={contactusbanner} />
      <img className="imgmob" src={mob20} />

      </picture> 
        <div className=" container overlay-text-bg2">
          <h2>Contact us</h2>
          <p><Link to="/" style={{textDecoration:"none"}}>
            Home </Link><span>/ Contact Us</span>
          </p>
        </div>
      </div>

      <div class="wrap">
        <div class="container">
          <div class="text-part ">
            <h6>Our origins</h6>
            <p>
              Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting
              Industry <br />. Lorem Ipsum Has Been The Industry’s Standard
              Dummy Text Ever Since The 1500S, When An <br /> Unknown Printer.
              Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting
              Industry
              <br /> . Lorem Ipsum Has Been The Industry’s Standard Dummy.
            </p>
          </div>
          <div class="card-container">
            <div class="card20 mb-3">
              <div class="row no-gutters">
                <div class="col-md-4 col-3">
                  <img src={icon} class="icon-margin" alt="..." />
                </div>
                <div class="col-md-8 col-9">
                  <div class="card20-body">
                    <h5 class="card20-title">Address</h5>
                    <p class="card20-text">454 read, Maadi, EGYPT</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="card20 mb-3">
              <div class="row no-gutters">
                <div class="col-md-4 col-3">
                  <img src={icon2} class="icon-margin" alt="..." />
                </div>
                <div class="col-md-8 col-9">
                  <div class="card20-body">
                    <h5 class="card20-title">Call Us Direct</h5>
                    <p class="card20-text">+20 1112255522</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="card20 mb-3">
              <div class="row no-gutters">
                <div class="col-md-4 col-3">
                  <img src={icon3} class="icon-margin" alt="..." />
                </div>
                <div class="col-md-8 col-9">
                  <div class="card20-body">
                    <h5 class="card20-title">Mail Address</h5>
                    <p class="card20-text">Rak@org.com</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Contactform />
      <Ask />
      <Footer />
    </div>
  );
}
