import React, { useState } from "react";
import "./style/Navbar.css";
import { Link } from "react-router-dom";
import logo from "/webapps/lms_react/src/assets/Logo-02.png";
import PROFILEICON from "/webapps/lms_react/src/assets/profile_3106773.png";
import offcanvasimg from "/webapps/lms_react/src/assets/Group 162327.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { faBagShopping } from "@fortawesome/free-solid-svg-icons";

export default function () {
  const [signedUp, setSignedUp] = useState(false);

  const handleSignUp = () => {
    setSignedUp(true);
  };

  const handlelogout = () => {
    setSignedUp(false);
  };
  return (
    <div>
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container">
          <a class="navbar-brand mx-auto d-block text-center" href="/">
            <img src={logo} className="rak-logo" />
          </a>

          <button class="navbar-toggler"
            aria-expanded="false"
            aria-label="Toggle navigation" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling">
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="offcanvas offcanvas-start d-flex justify-content-center" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">

            <div class="offcanvas-header ">
              <div className="green-container">
              <Link to="/"><img src={logo}  className="rak-logo"/></Link>
              <Link to="/signuppage">
              <button class="off-canvas-sign-up">Signup</button></Link>
              </div>
              <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>

            </div>
            <div class="offcanvas-body">

            <ul class="offcanvaslist">
            <Link to="/" style={{textDecorationLine:"none"}}>  
            <li class="offcanvaslist-item" style={{color:"#212529"}}>
              Home
            </li>
            </Link>
                <li class="nav-item dropdown offcanvaslist-item">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Tracks
                </a>
                <ul
                  class="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <a class="dropdown-item" href="#">
                      Action
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Another action
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Something else here
                    </a>
                  </li>
                </ul>
              </li>
              <Link to="/allcourses" style={{textDecoration:"none",textDecorationColor:"none",textDecorationColor:"none",color:"#212529"}}>
              <li class="offcanvaslist-item">
              Courses
            </li>
            </Link>
            
            <li class="offcanvaslist-item">
              Resources
            </li>
            <Link to="/contactus" style={{textDecoration:"none",textDecorationColor:"none",textDecorationColor:"none",color:"#212529"}}>
            <li class="offcanvaslist-item">
              Contact us
            </li>
            </Link>


            </ul>
         

            </div>
          </div>
          <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
            <ul class="mx-auto navbar-nav">
              <li class="nav-item active">
                <Link class="nav-link" to="/">
                  Home
                </Link>
              </li>
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Tracks
                </a>
                <ul
                  class="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <a class="dropdown-item" href="#">
                      Action
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Another action
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Something else here
                    </a>
                  </li>
                </ul>
              </li>

              <li class="nav-item dropdown">
                <Link class="nav-link" to="/allcourses">
                  {/* <a
                     class="nav-link dropdown-toggle"
                     href="#"
                     id="navbarDropdownMenuLink"
                     role="button"
                     data-bs-toggle="dropdown"
                     aria-expanded="false"
                     > */}
                  Courses
                  {/* </a> */}
                </Link>

                {/* <ul
                     class="dropdown-menu"
                     aria-labelledby="navbarDropdownMenuLink"
                     >
                     <li>
                        <a class="dropdown-item" href="#">
                        Action
                        </a>
                     </li>
                     <li>
                        <a class="dropdown-item" href="#">
                        Another action
                        </a>
                     </li>
                     <li>
                        <a class="dropdown-item" href="#">
                        Something else here
                        </a>
                     </li>
                  </ul> */}
              </li>
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Workshop
                </a>
                <ul
                  class="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <a class="dropdown-item" href="#">
                      Action
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Another action
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Something else here
                    </a>
                  </li>
                </ul>
              </li>
              <li class="nav-item ">
                <a class="nav-link" href="#">
                  Resources{" "}
                </a>
              </li>
              <li class="nav-item ">
                <Link class="nav-link" to="/contactus">
                  Contactus
                </Link>
              </li>
            </ul>
          </div>
          <div class="dddd">
          <ul class="navbar-nav ml-auto" style={{ marginLeft: "auto" }}>
            <li class="nav-item4">
              <Link to="/loginpage">
                <button class="login-btn" href="#">
                  <img
                    src={PROFILEICON}
                    style={{
                      width: "20px",
                      height: "18px",
                      marginRight: "5px",
                    }}
                  />
                  Log in
                </button>
              </Link>
            </li>
          </ul>
          </div>
          <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
            {/* {signedUp ? ( */}
            {/* <ul class="navbar-nav ml-auto" style={{ marginLeft: "auto" }}>
      <li class="nav-item" style={{ position: "relative" }}>
        <FontAwesomeIcon
          icon={faBagShopping}
          style={{ width: "22px", height: "22px", marginTop: "20px", marginRight: "20px" }}
        />
        <span class="badge">5</span>
      </li>
      <li class="nav-item" style={{ position: "relative" }}>
        <FontAwesomeIcon
          icon={faBell}
          style={{ width: "22px", height: "22px", marginTop: "20px", marginRight: "10px" }}
        />
        <span class="badge">3</span>
      </li>
      <div class="dropdown-container">
        <details class="dropdown right">
          <summary class="avatar">
            <img src="https://gravatar.com/avatar/00000000000000000000000000000000?d=mp" />
          </summary>
          <ul>
            <li>
              <p>
                <span class="block bold">Jane Doe</span>
                <span class="block italic">jane@example.com</span>
              </p>
            </li>
            <li>
              <a href="#">Account</a>
            </li>
            <li>
              <a href="#">Settings</a>
            </li>
            <li>
              <a href="#">Help</a>
            </li>
            <li class="divider"></li>
            <li>
              <a href="#" onClick={handlelogout}>Logout</a>
            </li>
          </ul>
        </details>
      </div>
    </ul> */}
            {/* ) : ( */}
            <ul class="navbar-nav ms-auto" style={{ marginLeft: "auto" }}>
              {/* <li class="nav-item">
        <Link to="/loginpage">
          <button class="login-btn" href="#">
            <img
              src={PROFILEICON}
              style={{
                width: "20px",
                height: "18px",
                marginRight: "5px",
              }}
            />
            Log in
          </button>
        </Link>
      </li> */}
              {/* onClick={handleSignUp} opens icons when user loged in  */}
              <li class="nav-item" style={{zIndex:"7"}}>
                <Link to='/signuppage'>
                  <button class="signup-btn" href="#" >
                    Sign up
                  </button>
                </Link>
              </li>
            </ul>
            {/* )} */}
          </div>

        </div>
      </nav>
    </div>
  );
}
