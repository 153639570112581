import { TOGGLE_CATEGORY } from '../Types/types';

const initialState = {
    filters: {
      category1: false,
      category2: false,
      category3: false,
      category4: false,
      category5: false,
      category6: false,
      category7: false,
    },
  };

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_CATEGORY:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.category]: action.payload.checked,
        },
      };
    default:
      return state;
  }
};

export default filterReducer;

