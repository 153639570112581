import React from "react";
import "./style/ask.css";

export default function Ask() {
  return (
    <div class="ask-container pb-4"style={{backgroundColor:"#F7FCFF", borderRadius:"10%"}} >
        <div class="text-section container">
      <h5>Frequently asked questions</h5>
      <p>
        You will find here the answers to the frequently asked question and our
        community <br/> guidlines, that allow us to study in a motivating and secure
        environment
      </p>
    </div>
    <div class="accordion container accordtion2" id="accordionExample3">
    <div class="accordion-item " >
        <h2 class="accordion-header" id="collapse1">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                aria-expanded="true" aria-controls="collapseOne">
                How can I Lorem Ipsum is simply dummy text ?
            </button>
        </h2>
        <div id="collapseOne" class="accordion-collapse collapse show collapse" data-bs-parent="#accordionExample">
            <div class="accordion-body ">
            <p>We currently only need a Zoom Account to be able to join our study calls. Simply click on the button on our website or here, and enter your zoom credentials. You will then be taken to the Zoom app directly or you can join our call via your browser</p>
            </div>
        </div>
    </div>
    <div class="accordion-item">
        <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                Can you unmute Lorem Ipsum is  dummy text ?
            </button>
        </h2>
        <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
            <div class="accordion-body">
                <strong>This is the second item's accordion body.</strong> It is hidden by default, until the
                collapse plugin adds the appropriate classes that we use to style each element. These classes
                control the overall appearance, as well as the showing and hiding via CSS transitions. You can
                modify any of this with custom CSS or overriding our default variables. It's also worth noting that
                just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit
                overflow.
            </div>
        </div>
    </div>
    <div class="accordion-item">
        <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                Where can I Lorem Ipsum is  dummy text ?
            </button>
        </h2>
        <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
            <div class="accordion-body">
                <strong>This is the third item's accordion body.</strong> It is hidden by default, until the
                collapse plugin adds the appropriate classes that we use to style each element. These classes
                control the overall appearance, as well as the showing and hiding via CSS transitions. You can
                modify any of this with custom CSS or overriding our default variables. It's also worth noting that
                just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit
                overflow.
            </div>
        </div>
    </div>
</div>
   
    </div>
  );
}
