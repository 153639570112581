import React from "react";
import backgroundImage from "/webapps/lms_react/src/assets/Bg.png";
import "./style/home.css";
import { useHistory } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import 'swiper/css/navigation';
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css/pagination";
import sliderpic from "/webapps/lms_react/src/assets/Group 50.png";
import firstcard from "/webapps/lms_react/src/assets/Shape.png";
import seccard from "/webapps/lms_react/src/assets/Group 48.png";
import thirdcard from "/webapps/lms_react/src/assets/Group 49.png";
import horizontal1 from "/webapps/lms_react/src/assets/pic.png";
import singlecoursepic from "/webapps/lms_react/src/assets/pic2.png";
import ebay from "/webapps/lms_react/src/assets/ebay.png";
import oracle from "/webapps/lms_react/src/assets/oracle.png";
import google from "/webapps/lms_react/src/assets/google.png";
import meta from "/webapps/lms_react/src/assets/meta.png";
import coursera from "/webapps/lms_react/src/assets/coursera.png";
import techer1 from "/webapps/lms_react/src/assets/techer1.png";
import techer2 from "/webapps/lms_react/src/assets/techer2.png";
import techer3 from "/webapps/lms_react/src/assets/techer3.png";
import google1 from "/webapps/lms_react/src/assets/google.svg";
import linkedin2 from "/webapps/lms_react/src/assets/linkedin.svg";
import facebook3 from "/webapps/lms_react/src/assets/facebook .svg";
import social from "/webapps/lms_react/src/assets/social.png";
import card2 from "/webapps/lms_react/src/assets/222.png";
import bag from "/webapps/lms_react/src/assets/Group 19.svg";
import paper from "/webapps/lms_react/src/assets/Group 18.svg";
import secsecmobileviewicon from "/webapps/lms_react/src/assets/mobileviewsec2.png";
import GalleryComponent from "./Gallery";
import Register from "./register";
import Reverseswiper from "./Reverseswiper";
import Firstslider from "./Firstslider";
import Navbar from "./Navbar";
import Footer from "./footer";
import Allcategoryhome from "./allcategoryhome";
import firstcardoval from "/webapps/lms_react/src/assets/Group 162314.svg";
import { Link } from 'react-router-dom';


//install Swiper modules

export default function Home() {
  function handleExploreClick() {
    window.location.href = '/coursedetails';
  }
  return (
    <>
      <Navbar />
      <div class="container-fluid first-section">

        <Firstslider />
      </div>
      {/* <div class="container second-section pb-5 ">
        <div class="card1">
          <img class="app-img1" src={firstcardoval} alt="Card image cap" />
          <div class="card-body1">
            <p class="card-title">Best Teachers</p>
            <p class="card-text">
              Lorem Ipsum is simply dummy text of the printing typesetting
              industry.
            </p>
            <p class="read-more">Read more →</p>
          </div>
        </div>
        <div class="card1">
          <img class="app-img1" src={seccard} alt="Card image cap" />
          <div class="card-body1">
            <p class="card-title">Professional Courses</p>
            <p class="card-text">
              Lorem Ipsum is simply dummy text of the printing typesetting
              industry.
            </p>
            <p class="read-more">Read more →</p>
          </div>
        </div>
        <div class="card1">
          <img class="app-img1" src={thirdcard} alt="Card image cap" />
          <div class="card-body1">
            <p class="card-title">Online Learning</p>
            <p class="card-text">
              Lorem Ipsum is simply dummy text of the printing typesetting
              industry.
            </p>
            <p class="read-more">Read more →</p>
          </div>
        </div>
      </div> */}

      <div className="container">
        <Allcategoryhome />
      </div>

      <div class="container-fluid pb-4">
          <div class="card5">
            <div class="card-horizontal">
              <div class="img-square-wrapper">
                <picture>
                  <source
                    media="(max-width:767px)"
                    src={secsecmobileviewicon}
                  />
                  <source
            srcset={horizontal1}
            media="(min-width: 768px) and (max-width: 1023px)"
          />
                  <source
                    media="(min-width: 1024px)"
                    src={horizontal1}
                  />
                  <img
                    class="card-photo"
                    src={horizontal1}
                    alt="Card image cap"
                  />    </picture>

              </div>
              <div class="card5-body">
                <h6 class="card-title-1">ABOUT</h6>

                <h4 class="card-title-2">We Are Experts in E-learning</h4>

                <h5 class="card-title-3">Distant orb has power</h5>

                <p className="card-par">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry’s
                  standard dummy text ever since the 1500s, when an unknown
                  printer.
                </p>
                <div className="explore-sec"onClick={handleExploreClick}>
                  {/* <Link to="/coursedetails">
                    <button class="explore-btn" >Explore</button>
                  </Link> */}
                </div>
              </div>

            </div>
          </div>
        </div>



      {/* <div class="findsection">
        <div class="container companies_section">
          <h6 class="">70,000+ teams</h6>

          <h5>Find Training Success With Lms</h5>

          <ul className="compaines">
            <li>
              <img src={ebay} />
            </li>
            <li>
              <img src={oracle} />
            </li>
            <li>
              <img src={google} />
            </li>
            <li>
              <img src={meta} />
            </li>
            <li>
              <img src={coursera} />
            </li>
          </ul>
        </div>
      </div> */}

      {/* <div class="techers-section pb-5">
        <div class="container techers-container">
          <h6 class="">TEACHERS</h6>

          <h5>Experts Advisors</h5>

          <div class="techers-cards">
            <div class="card0">
              <div class="tech-img-container">
                <img src={techer1} class="techer-img" />
              </div>
              <div class="card-body-techer">
                <h5 class="techer-title">Shady Abdullah</h5>
                <h5 class="teacher-text">Science</h5>
                <img src={social} />
              </div>
            </div>
            <div class="card0">
              <div class="tech-img-container">
                <img src={techer2} class="techer-img" />
              </div>
              <div class="card-body-techer">
                <h5 class="techer-title">Shady Abdullah</h5>
                <h5 class="teacher-text">Science</h5>
                <img src={social} />
              </div>
            </div>
            <div class="card0">
              <div class="tech-img-container">
                <img src={techer3} class="techer-img" />
              </div>
              <div class="card-body-techer">
                <h5 class="techer-title">Shady Abdullah</h5>
                <h5 class="teacher-text">Science</h5>
                <img src={social} />
              </div>
            </div>
          </div>
        </div>
        <div class="loadmore-btn">
          <button class="load-more-btn">See All</button>
        </div>
      </div> */}

        <div class="container-fluid pb-5">
        <div class="row">
          <div class="card5">
            <div class="card-horizontal2">
              <div class="card6-body">
                <h6 class="card-title-1">FEATURES</h6>

                <h4 class="card-title-2">Premium Courses</h4>

                <h5 class="card-title-3">Lifts the Dummy text </h5>

                <p className="card-par">
                  Lorem Ipsum is simply dummy text of the printing and <br />{" "}
                  typesetting industry Lorem Ipsum.
                </p>
                <div class="contact-info">
                  <div class="contact-info-item">
                    <div class="contact-info-icon">
                      <img src={bag} />
                    </div>

                    <div class="contact-info-content">
                      <h4>Free Support</h4>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting.
                      </p>
                    </div>
                  </div>
                  <div class="contact-info-item">
                    <div class="contact-info-icon">
                      <img src={paper} />
                    </div>

                    <div class="contact-info-content">
                      <h4>Big Bonuses</h4>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="img-square-wrapper">
                <img class="card-photo2" src={card2} alt="Card image cap" />
              </div>
            </div>
          </div>
        </div>
      </div> 

      {/* <div class="video-section pb-5 pt-5">
        <div class="container video-container">
          <h6 class="">GALLERY</h6>

          <h5>Photo & Video</h5>
        </div>
      </div>
      <div>
        <GalleryComponent />
      </div> */}

      <div>
        <Reverseswiper />
      </div> 

       <div>
        <Register />
      </div>  
      <Footer />
    </>
  );
}
