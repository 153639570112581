import React, { useEffect } from "react";
import "./style/footer.css";
import google from "/webapps/lms_react/src/assets/google.svg";
import linkedin from "/webapps/lms_react/src/assets/linkedin.svg";
import facebook from "/webapps/lms_react/src/assets/facebook.svg";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import socialicon from "/webapps/lms_react/src/assets/social-footer.png"
export default function Footer() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div style={{backgroundColor:"#7D888D"}}>
    <footer id="footer" className="container">
      <div class=" pt-5 pb-5 footer">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6 col-12 about-company">
              <h1>LMS</h1>
              <p class="pt-1 footer-left-text">
                Lorem Ipsum is simply dummy text of the <br/> printing and typesetting
                industry.{" "}
              </p>
              {/* <p class="footer-left-text">We are continuously working to improve the accessibility of our web experience
                for everyone, and we welcome feedback and accommodation requests.</p> */}

              <img class="pb-4" src={socialicon} />
            </div>
            <div class="footer-list-div1 col-md-3 col-12 links pt-3">
              <h6 class="pb-2">POPULAR COURSES</h6>
              <p class="footer-left-text">
              Digital Ecosystem Infrastructure <br/> Nutrition, Exercise and Sports <br/> Leadership and Influence
              </p>
            </div>
            <div class="footer-list-div2 col-md-3 col-12 links pt-3">
              <h6>NEWSLETTER</h6>
              <form  style={{paddingTop:"5px"}}>
                <input
                  class="newsletter-input"
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Your Email"

                />
                <div>
                  <button class="subscibe_btn btn btn-primary">
                    Subscribe
                  </button>
                </div>
              </form>
            </div>
          </div>
         
        </div>
        <div class="last-section row  pt-3 ">
            <div class=" col-sm-6">
              <p class="pt-2">Copyright © 2024 LMS Education. All Rights Reserved.</p>
            </div>
            <div class=" col-sm-3">
              <p class="pt-2">Terms of Conditions</p>
            </div>
            <div class=" col-sm-3">
              <p class="pt-2">Privacy Policy</p>
            </div>
          </div>
      </div>
    </footer>
    </div>
  );
}
