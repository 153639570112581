import { SET_SEARCH_TERM, SET_SEARCH_RESULTS } from '../Types/types';

const initialState = {
  data: [],
  Records: [],
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH_TERM:
      return { ...state, data: action.payload };
    case SET_SEARCH_RESULTS:
      return { ...state, Records: action.payload };
    default:
      return state;
  }
};

export default searchReducer;